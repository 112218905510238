<template>
  <div :style="{background: headerType !== 'none' ? '#F8F8F8' : 'white'}" style="padding: 4px 8px">
    <div class="is-flex">
      <div class="has-text-grey-light has-text-weight-medium" style="font-size: 14px">{{
          getSelectedText(column.label)
        }}
      </div>
      <b-icon :class="activeIcon(column.field)" class="ml-1 mr-5" style="margin-top: 3px" icon="arrow-up"
              size="is-small"></b-icon>
    </div>
    <div class="is-flex" style="margin-top: -24px" v-if="headerType">
      <div @click.stop="emitFilterClicked($event)" class="ml-auto">

        <b-tooltip v-if="tooltip" :label="tooltip">
          <b-icon id="iconId" :icon="getIcon()" size="is-small" style="margin-top: 6px; cursor: pointer"
                  :class="[isFilterSelected() ? 'has-text-primary' : 'has-text-grey-light']">
          </b-icon>
        </b-tooltip>
        <b-icon v-else id="iconId" :icon="getIcon()" size="is-small" style="margin-top: 6px; cursor: pointer"
                :class="[isFilterSelected() ? 'has-text-primary' : 'has-text-grey-light']">
        </b-icon>

        <context-search ref="contextMenu" :element-id="elementId" @onTextChanged="onTextChanged">
          <div v-if="headerType === 'dropdown'" style="width: 200px;" class="is-flex is-flex-direction-column">
            <b-dropdown ref="dropDown" aria-role="list" class="is-pulled-right p-0"
                        v-model="selectedOptions" @change="onDropdownChange"
                        multiple>

              <b-dropdown-item v-for="option in options" :key="option" :value="option" aria-role="listitem">
                {{ option }}
              </b-dropdown-item>
            </b-dropdown>

          </div>
          <div v-if="headerType === 'search'" style="width: 200px">
            <b-input placeholder="Search..."
                     v-model="searchText"
                     icon="filter"
                     size="is-small"
                     class="m-0"
                     @input="onTextChanged"
                     :icon-right="searchText ? 'close-circle' : ''"
                     icon-right-clickable>
            </b-input>
          </div>
          <div v-if="headerType === 'date'" style="width: 200px">
            <b-datepicker
                v-model="selectedDate"
                :show-week-number="false"
                placeholder="Click to select..."
                icon="calendar-today"
                :icon-right="selectedDate ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDate"
                position="is-bottom-left"
                size="is-small"
                locale="en-GB"
                @input="onDateChanged"
                trap-focus>
            </b-datepicker>
          </div>
          <div v-if="headerType === 'time'" style="width: 200px">
            <b-timepicker
                v-model="selectedTime"
                placeholder="Click to select..."
                icon="clock"
                :icon-right="selectedTime ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearTime"
                position="is-bottom-right"
                size="is-small"
                locale="en-GB"
                hour-format="24"
                @input="onTimeChanged">
            </b-timepicker>
          </div>
        </context-search>
      </div>
    </div>
  </div>
</template>

<script>
import ContextSearch from "@/components/context-search/context-search.vue";

export default {
  name: "table-header",
  components: {ContextSearch},
  props: {
    column: {},
    headerType: {
      type: String,
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    elementId: {
      type: String
    },
    tooltip: {
      type: String
    },
    tableHeaderData: {
      default:{},
      required: true
    }
  },
  data() {
    return {
      searchText: '',
      selectedDate: null,
      selectedTime: null,
      selectedOptions: [],
    }
  },
  mounted() {
    //restore previous state
    let tableHeaderData = this.tableHeaderData
    if (this.headerType === 'search') {
      console.log('This column', this.column)
      this.searchText = tableHeaderData.filters[this.column.field]
    } else if (this.headerType === 'dropdown') {
      this.selectedOptions = tableHeaderData.filters[this.column.field]
    } else if (this.headerType === 'date') {
      this.selectedDate = tableHeaderData.filters[this.column.field]
    } else if (this.headerType === 'time') {
      this.selectedTime = tableHeaderData.filters[this.column.field]
    }
  },
  watch: {
    activeFilter: function (newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal.field === this.column.field) {
        this.$refs.contextMenu.toggleMenu(newVal.event, {})
        if (this.$refs.dropDown && !this.$refs.dropDown.expanded) {
          this.$refs.dropDown.toggle()
        }

      } else {
        this.$refs.contextMenu.hideContextMenu()
        if (this.$refs.dropDown && this.$refs.dropDown.expanded) {
          this.$refs.dropDown.toggle()
        }
      }
    },
  },
  methods: {
    getIcon() {
      if (this.headerType === 'dropdown') {
        return 'filter'
      } else if (this.headerType === 'search') {
        return 'filter'
      } else if (this.headerType === 'date') {
        return 'calendar-today'
      } else if (this.headerType === 'time') {
        return 'clock'
      }
    },
    isFilterSelected() {
      if (this.headerType === 'dropdown' && this.selectedOptions.length > 0) {
        return true
      } else if (this.headerType === 'search' && this.searchText != null && this.searchText.length > 0) {
        return true
      } else if (this.headerType === 'date' && this.selectedDate != null) {
        return true
      } else if (this.headerType === 'time' && this.selectedTime != null) {
        return true
      }

      return false
    },
    getSelectedText(columnLabel) {
      return columnLabel
    },
    onDropdownChange(value) {
      this.$emit('onValueChanged', value)
      this.sort(this.column, value)
    },
    onTextChanged(value) {
      this.searchText = value
      this.$emit('onValueChanged', value)
      this.sort(this.column, value)
    },
    onDateChanged(value) {
      this.$emit('onValueChanged', value)
      this.sort(this.column, value)
    },
    onTimeChanged(value) {
      this.$emit('onValueChanged', value)
      this.sort(this.column, value)
    },
    clearDate() {
      this.selectedDate = null
      this.onTimeChanged(null)
    },
    clearTime() {
      console.log('Clear time called')
      this.selectedTime = null
    },
    emitFilterClicked(event) {
      console.log('Event', event)
      if (this.$refs.contextMenu && event.target.parentElement.id === 'iconId') {
        this.$emit('onFilterClicked', event)

        let tableHeaderData = this.tableHeaderData
        tableHeaderData.activeFilterField = {field: this.column.field, event: event}

      }
      //time picker - clear time click problem workaround
      if (this.headerType === 'time' && event.target.className === 'mdi mdi-close-circle') {
        this.clearTime()
        this.onTimeChanged(null)
      } else if (this.headerType === 'search' && event.target.className === 'mdi mdi-close-circle') {
        console.log('Event On clear search')
        //this.clearSearch()
        this.onTextChanged('')
      }
    },
    activeIcon(field) {
      console.log('Active icon: ', this.activeSorted)
      if (this.activeSorted && this.activeSorted.field === field) {
        if (this.activeSorted.order === 'desc') {
          return 'icon-visible is-desc'
        }
        return 'icon-visible'
      }
      return 'icon-invisible'
    },
    sort(column, value) {
      console.log('Sort called: ', column, value)
      let tableHeaderData = this.tableHeaderData
      tableHeaderData.filters[column.field] = value
      //this.$store.dispatch('setTableHeaderData', tableHeaderData)
      this.$emit('onSort', tableHeaderData)
    },
  },
  computed: {
    activeFilter() {
      return this.tableHeaderData.activeFilterField
    },
    activeSorted() {
      return this.tableHeaderData.activeSorted
    }
  }
}
</script>

<style scoped>
.header-icon {
  position: absolute;
  bottom: 50%;
  left: 100%;
  transform: translateY(50%);
}

.icon-visible {
  visibility: visible;
}

.icon-invisible {
  visibility: hidden;
}

.is-desc {
  transform: rotate(180deg) !important;
}
</style>