<template src="./trajectory-data.html"></template>

<script>
import arrowImage from '../../../../assets/arrow.png';
import {MglGeojsonLayer, MglMap, MglMarker} from "vue-mapbox";
import {bbox, bearing, lineString} from "@turf/turf";
import {getRiskRatingClass} from "@/utils/utils";

export default {
  name: "trajectory-data",
  components:{
    MglMap,MglGeojsonLayer,MglMarker,
  },
  data(){
    return {
      mapStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true',
      mapActive: true,
      mapCenter: [144.94444, -37.80193],
      mapZoom: 10,
      options:['West->North', 'West->North1'],
      filterOptions:['Date1', 'Date2', 'Date3'],
      allSites: [
          {pathName: 'Path 1', userType: 'Car', speed: 123, date:'01.01.2023.', time:'01:15PM'},
          {pathName: 'Path 2', userType: 'Car', speed: 123, date:'01.01.2023.', time:'01:15PM'},
          {pathName: 'Path 3', userType: 'Car', speed: 123, date:'01.01.2023.', time:'01:15PM'},
          {pathName: 'Path 4', userType: 'Car', speed: 123, date:'01.01.2023.', time:'01:15PM'},
          {pathName: 'Path 5', userType: 'Car', speed: 123, date:'01.01.2023.', time:'01:15PM'},
          {pathName: 'Path 6', userType: 'Car', speed: 123, date:'01.01.2023.', time:'01:15PM'},
      ],
      getRatingClass(property){
        return getRiskRatingClass(property)
      },
      geoJsonSource:{
        'id':'test'
      },
      geoJsonPath:{},
      layer:{'id': 'route'},
      layerHover:{},
      sourceId:'test',
      arrowSourceId:'arrowId',
      arrowLayer:{},
      arrowSource:{'id':'arrowId'},
    }
  },
  mounted() {

  },
  created() {
    this.map = null
    const points = [
      [21.921572, 43.330199],
      [21.921605, 43.330197],
      [21.921643, 43.330198],
      [21.921669, 43.330197],
      [21.921692, 43.330197],
      [21.921725, 43.330200],
      [21.921735, 43.330214],
      [21.921735, 43.330230],
      [21.921733, 43.330246]
    ]

    const points1 = [
      [21.92150, 43.3300],
      [21.921605, 43.3300],
      [21.921640, 43.3300],
      [21.921660, 43.3300],
      [21.921690, 43.33010],
      [21.921720, 43.33020],
      [21.921730, 43.33027],
      [21.921730, 43.3307],
      [21.921730, 43.3306]
    ]


    // A simple line from origin to destination.
    this.geoJsonPath = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': points
          }
        },
        {
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': points1
          }
        }
      ]
    }

    this.geoJsonSource = {
      'type': 'geojson',
      'data': this.geoJsonPath
    }

    this.layer = {
      'id': 'route',
      'source': this.sourceId,
      'type': 'line',
      'paint': {
        'line-width': 5,
        'line-color': '#007cbf'
      }
    }

    this.arrowSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: points[8],
            },
            properties: {
              rotation: bearing(points[7], points[8]),
            },
          },
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: points[5],
            },
            properties: {
              rotation: bearing(points[4], points[5]),
            },
          },
        ]
      }
    }

    this.arrowLayer = {
      id: 'arrows',
      type: 'symbol',
      source: this.arrowSourceId,
      layout: {
        'icon-image': 'arrow-marker',
        'icon-size': 0.06,
        'icon-rotate': {
          type: 'identity',
          property: 'rotation',
        },
        'icon-anchor': 'center',
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      }
    }

  },
  methods:{
    onMapLoaded(map) {
      this.map = map.map;

      console.log('Map loaded: ', this.map)
      this.map.on('zoom', () => {
        this.zoom = this.map.getZoom();
      });
      this.map.on('click', function(e) {
        let features = e.target.queryRenderedFeatures(e.point, { layers: ['route'] });
        if (typeof e.target.getLayer('selectedRoad') !== "undefined" ){
          e.target.removeLayer('selectedRoad')
          e.target.removeSource('selectedRoad');
        }
        if (!features.length) {
          return;
        }

        let feature = features[0];
        console.log(feature.toJSON());
        e.target.addSource('selectedRoad', {
          "type":"geojson",
          "data": feature.toJSON()
        });
        e.target.addLayer({
          "layerId": feature.id,
          "id": "selectedRoad",
          "type": "line",
          "source": "selectedRoad",
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          "paint": {
            "line-color": "red",
            "line-width": 6
          }
        });
      });

      this.map.on('mousemove', function(e) {
        var features = e.target.queryRenderedFeatures(e.point, { layers: ['route'] });
          e.target.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      this.map.loadImage(
          arrowImage,
          (error, image) => {
            if (error) throw error;
            this.map.addImage('arrow-marker', image);
          }
      );

      const points = [
        [21.921572, 43.330199],
        [21.921605, 43.330197],
        [21.921643, 43.330198],
        [21.921669, 43.330197],
        [21.921692, 43.330197],
        [21.921725, 43.330200],
        [21.921735, 43.330214],
        [21.921735, 43.330230],
        [21.921733, 43.330246]
      ]

      setTimeout(() => {
        this.map.resize()
        let line = lineString([points[0], points[8]]);
        let bb = bbox(line);
        this.map.fitBounds(bb, {padding: 300})

      }, 500)
    },
  }
}
</script>

<style scoped lang="scss" src="./trajectory-data.scss"></style>