<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content p-1">
            <tmc-map v-if="serverConfigurationData" :server-configuration-data="serverConfigurationData" :hourly-report="report.tmcHourlyReport"></tmc-map>
            <volume-operations-chart :volume-report="report.tmcVolumeReport"></volume-operations-chart>
<!--            <speed-operation-chart :speed-report="report.speedReport"></speed-operation-chart>
            <speed-distribution-chart></speed-distribution-chart>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VolumeOperationsChart from "@/modules/safety-module/components/operations/volume-operations-chart.vue";
import SpeedOperationChart from "@/modules/safety-module/components/operations/speed-operation-chart.vue";
import SpeedDistributionChart from "@/modules/safety-module/components/operations/speed-distribution-chart.vue";
import TmcMap from "@/modules/safety-module/components/operations/tmc-map.vue";

export default {
  name: "tmc-map-operations",
  components: {
    TmcMap, VolumeOperationsChart
  },
  props: {
    serverConfigurationData: {
      type: Object
    },
    report:{
      type: Object
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
}
</script>

<style scoped>

</style>