import {
    calculateRiskSumFromWeeklyReport, calculateSimpleRisk,
    calculateToDcaFromWeeklyReport,
    capitalizeFirstLetter, getMatrixReport, risks
} from "@/utils/utils";
import moment from "moment/moment";
export default {
    getAllProjects: (state) => {
        return state.allProjects
    },
    getSelectedProject:(state) =>{
      return state.selectedProject
    },
    getAllSites: (state) => {
        return state.allSites.map(site=>{return site.site})
    },
/*    getAllDevicesForProject: (state) => {
        return state.selectedProjectsDevices
    },*/
    getUserInfo: (state) => {
        return state.user
    },
    getTableData: (state) => {
        return state.tableData
    },
    getFilteredSites: (state) => {
        if (state.allSites.length !== 0) {
            console.log('All sites:', state.allSites)

            //Extract all data device reports

            let allSites = state.allSites
            let allDataDeviceReports = []

            state.allSites.forEach(data => {
                if(data.dataDevicesReports && data.dataDevicesReports.length > 0)
                allDataDeviceReports.push(...data.dataDevicesReports)
            })


            if (state.searchQueryData.query != null && state.searchQueryData.query.length > 0) {
                allDataDeviceReports = allDataDeviceReports.filter(ddReport => ddReport.dataDevice.name.toLowerCase().startsWith(state.searchQueryData.query.toLowerCase()))
            }

            allDataDeviceReports = allDataDeviceReports.map((ddReport) => {


                return {
                    id: ddReport.dataDevice._id,
                    projectId: ddReport.dataDevice.projectId,
                    extDeviceIds: ddReport.dataDevice.extDeviceId,
                    name: ddReport.dataDevice.name,
                    riskRating: ddReport.report ? calculateRiskSumFromWeeklyReport(ddReport.report) : '-',
                    topDca: ddReport.report ? calculateToDcaFromWeeklyReport(ddReport.report) : '-',
                    location: ddReport.dataDevice.location,
                    incidentsCount: ddReport.incidentsCount,
                    weeklyReport: ddReport.weeklyReport,
                    hourlyReport: ddReport.hourlyReport,
                    severityReport: ddReport.severityReport,
                    tmcOperationsReport: ddReport.tmcOperationsReport,
                    tmcMapConfiguration:ddReport.tmcMapConfiguration,
                    roadUserMatrixReport: getMatrixReport(ddReport.roadUserMatrixReport)
                }
            })

            //Alphabetical sort
            if (state.searchQueryData.sort.text === 'Alphabetical order') {
                allDataDeviceReports = allDataDeviceReports.sort((a, b) => a.name.localeCompare(b.name))
            } else if (state.searchQueryData.sort.text === 'Risk rating') {
                allDataDeviceReports = allDataDeviceReports.sort((a,b)=>risks[a.riskRating] - risks[b.riskRating])
            } else if (state.searchQueryData.sort.text === 'Top DCA') {
                allDataDeviceReports = allDataDeviceReports.sort((a, b) => b.topDca - a.topDca)
            }

            console.log('Sort is: ', state.searchQueryData.sort)
            console.log('All DD reports: ', allDataDeviceReports)

            return allDataDeviceReports
        } else {
            return []
        }
    },
    getIncidentTable: (state) => {
        let filters = state.tableHeaderData.filters
        console.log('Filters: ', filters)
        let table = []
        table = state.tableData.map((td) => {
            return {
                eventId: td._id,
                date: new Date(td.eventAt),
                time: new Date(td.eventAt),
                risk: td.severityRating ? capitalizeFirstLetter(td.severityRating) : '-',
                dca: td.data.DCA,
                pet: td.data.value,
                ttc: 'No data',
                kineticEnergy: 'No data',
                userTypes: (td.data.leadRoadUser && td.data.leadRoadUser.roadUserClass ? td.data.leadRoadUser.roadUserClass : 'Undefined') + '/' + (td.data.followRoadUser && td.data.followRoadUser.roadUserClass ? td.data.followRoadUser.roadUserClass : 'Undefined'),
                videoId: td.videoMetadataId,
                videoSnippetId: td.videoSnippetMetadataId
            }
        })

        let filteredTable = table.filter(dt => filters && filters.risk.length > 0 ? filters.risk.includes(dt.risk) : true)
        filteredTable = filteredTable.filter(dt => filters && filters.pet != null && filters.pet.length > 0 ? dt.pet.toString().toLowerCase().includes(filters.pet.toLowerCase()) : true)
        filteredTable = filteredTable.filter(dt => filters && filters.dca != null && filters.dca.length > 0 ? dt.dca.toLowerCase().includes(filters.dca.toLowerCase()) : true)
        filteredTable = filteredTable.filter(dt => filters && filters.ttc != null && filters.ttc.length > 0 ? dt.ttc.toLowerCase().includes(filters.ttc.toLowerCase()) : true)
        filteredTable = filteredTable.filter(dt => filters && filters.kineticEnergy != null && filters.kineticEnergy.length > 0 ? dt.kineticEnergy.toLowerCase().includes(filters.kineticEnergy.toLowerCase()) : true)
        filteredTable = filteredTable.filter(dt => filters && filters.userTypes != null && filters.userTypes.length > 0 ? dt.userTypes.toLowerCase().includes(filters.userTypes.toLowerCase()) : true)

        filteredTable = filteredTable.filter(dt => filters && filters.date ? new Date(dt.date).toLocaleDateString() === new Date(filters.date).toLocaleDateString() : true)

        if (filters.time) {
            let startDate = new Date(filters.time.getTime())
            startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
            let utcTime = moment(startDate).utc()
            let time = moment(utcTime).format('HH:mm')
            console.log('Time: ', time)
            filteredTable = filteredTable.filter(dt => filters.time ? moment(dt.date).add(10, 'hours').utc().format('HH:mm').includes(time) : true)
        }

        return filteredTable
    },
    getAverageValues: (state) => {

        let averageValues = {
            riskRating: '-',
            lowestPet: 0,
            numberOfIncidents: 0,
            averageMaxSpeed: 0,
            lowestTtc: 0,
            eventsAboveKineticEnergy: 0
        }

        let maxSpeedLeading = 0
        let maxSpeedFollowing = 0


        if (state.tableData != null && state.tableData.length > 0) {
            averageValues.numberOfIncidents = state.tableData.length
            let risks = []
            state.tableData.forEach(data => {

                risks.push(data.severityRating)
                if(data.data.value && data.data.value >= 0.0){
                    if (averageValues.lowestPet === 0 || averageValues.lowestPet > data.data.value) {
                        averageValues.lowestPet = data.data.value
                    }
                }

                /*
                if(averageValues.lowestTtc === 0 || averageValues.lowestTtc > data.ttcValue){
                    averageValues.lowestTtc = data.ttcValue
                }
                */

                if(data.data.leadRoadUser && data.data.leadRoadUser.roadUserSpeed){
                    if (maxSpeedLeading === 0 || maxSpeedLeading < data.data.leadRoadUser.roadUserSpeed) {
                        maxSpeedLeading = data.data.leadRoadUser.roadUserSpeed
                    }
                }


                if(data.data.followRoadUser && data.data.followRoadUser.roadUserSpeed){
                    if (maxSpeedFollowing === 0 || maxSpeedFollowing < data.data.followRoadUser.roadUserSpeed) {
                        maxSpeedFollowing = data.data.followRoadUser.roadUserSpeed
                    }
                }


                averageValues.averageMaxSpeed = (maxSpeedLeading + maxSpeedFollowing) / 2
                if (averageValues.averageMaxSpeed > 0) {
                    averageValues.averageMaxSpeed = averageValues.averageMaxSpeed.toFixed(0)
                }

            })

            averageValues.riskRating = calculateSimpleRisk(risks)
        }


        return averageValues
    },
    getFileOperationsTableData: (state) => {
        return state.fileOperationsTableData
    }
}
