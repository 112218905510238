<template>
  <div>
    <div class="is-flex m-1 p-0" style="background: #fbfbfb; height: 80px">
      <div class="p-2 pl-4 mt-auto mb-auto mr-4">
        <div class="is-size-4 has-text-weight-bold">Volume distribution</div>
      </div>
      <b-field label="Traffic days" label-position="on-border" class="mt-auto mb-auto ml-auto mr-2">
        <b-select placeholder="Select traffic days" rounded expanded v-model="trafficDays" @input="onDaysChanged">
          <option value="averageDailyTraffic">Average Daily Traffic</option>
          <option value="weekdaysAverage">Weekday Average</option>
          <option value="weekendAverage">Weekend Average</option>
        </b-select>
      </b-field>
      <div style="width: 600px;" class="mr-0">
        <!--      <b-field>
                <b-slider v-model="mainHours" @change="onSliderChanged" :min="0" :max="96" :step="1" ticks tooltip-always :custom-formatter="(val) => formatSliderData(val)">
                </b-slider>
              </b-field>-->
        <div class="is-size-6" style="margin-left: 20px">{{ this.selectedHours[0] }} -
          {{ this.selectedHours[this.selectedHours.length - 1] }}
        </div>
        <div id="chart-line">
          <apex-chart style="margin-top: -30px; margin-right: 0px; margin-bottom: 8px" type="area" height="90"
                      :options="chartOptionsLine" :series="seriesLine"></apex-chart>
        </div>
      </div>
      <!--    <b-button v-if="refreshEnabled" icon-left="refresh" class="mt-auto mb-auto ml-1"
                    @click="onRefreshData"></b-button>-->

    </div>
    <div class="content p-4">
      <class-type-view v-if="configurationData" class="mt-1 mb-3" @onClassTypeChanged="onClassTypeChanged"
                       :items-data="sumOfEventsByClassType"
                       :sum-of-events="sumOfEvents">

      </class-type-view>
      <div style="margin-bottom: 32px">
        <div v-if="configurationData" id="map-container-tmc" style="height: 540px" class="mb-6"
             :key="selectedDataDevice.id">
          <MglMap
              ref="mapboxMapTmc"
              container="map-container-tmc"
              :accessToken="$env.VUE_APP_ACCESS_TOKEN"
              :center="mapCenter"
              :zoom="mapZoom"
              :mapStyle="mapStyle"
              @click="onMapClicked"
              @load="onMapLoaded">
            <div v-if="configurationData && configurationData.items">
              <div v-for="(item, index) in configurationData.items" :key="drawMode + index">
                <tmc-marker :item="item" :map-rotation="mapRotation"
                            :is-selected="item === selectedItem" :draw-mode="null"
                            @onMarkerClicked="onMarkerClicked(item)">
                </tmc-marker>
              </div>
            </div>
            <map-style-menu @onStyleChanged="changeStyle"></map-style-menu>
          </MglMap>
        </div>
        <div v-else style="height: 150px" class="is-flex is-align-items-center">
          <div class="is-flex is-flex-direction-column is-align-items-center ml-auto mr-auto">
            <div>
              <b-icon icon="cloud-question-outline"></b-icon>
            </div>
            <div>Please select start time - end time</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import MapStyleMenu from "@/components/map-style-menu/map-style-menu.vue";
import {MglMap} from "vue-mapbox";
import TmcMarker from "@/modules/safety-module/components/operations/tmc-marker.vue";
import {
  createHourlyReport,
  get15HoursMap,
  getClassTypes,
  getDateISOIgnoreTimezone, getFormatted15Minutes, getFormattedHourData, getHoursList,
  getMapData, getMapData1, getMiniChartMap,
  getShortTime
} from "@/utils/utils";
import ClassTypeView from "@/modules/safety-module/components/class-type-view/class-type-view.vue";

export default {
  name: "tmc-map",
  components: {MapStyleMenu, TmcMarker, ClassTypeView, MglMap},
  props: {
    serverConfigurationData: {
      type: Object
    },
    hourlyReport: {
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      mapStyle: '',
      mapCenter: [144.94444, -37.80193],
      mapZoom: 10,
      drawMode: null,
      mapRotation: 0,
      allDrawings: null,
      configurationData: null,
      chartData: {},
      selectedItem: null,
      clickedMarker: false,
      selectedStartTime: new Date('2024-03-01T00:00:00.000Z'),
      selectedEndTime: new Date('2024-03-30T00:00:00.000Z'),
      aiKitData: null,
      classTypesFilter: [],
      // refreshEnabled: false,
      sumOfEventsByClassType: {},
      sumOfEvents: 0,
      mainHours: [],
      selectedHours: [],
      trafficDays: 'averageDailyTraffic',
      chartOptionsLine: {
        chart: {
          id: 'chart1',
          height: 130,
          type: 'area',
          brush: {
            target: null,
            enabled: true
          },
          selection: {
            enabled: true,
            type: 'x',
            fill: {
              color: '#232323',
              opacity: 0.1
            },
            stroke: {
              width: 1,
              dashArray: 3,
              color: '#000000',
              opacity: 0.2
            },
            xaxis: {
              min: 0,
              max: 95,
            },
            yaxis: {
              min: undefined,
              max: undefined
            }
          },
          events: {
            selection: (chartContext, {xaxis, yaxis}) => {
              console.log('Selection changed:', xaxis)
              this.onPickChanged(xaxis)
            }
          }
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#008FFB'],
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          }
        },
        xaxis: {
          type: 'numeric',
          /*          tooltip: {
                      enabled: false
                    },*/
          labels: {
            show: false,
            formatter: function (value) {
              return Object.keys(getMiniChartMap())[Math.round(value)];
            }
          },
          categories: Array.from(Array(96).keys()),
        },
        yaxis: {
          labels: {
            show: false,
          },
        }
      },
      seriesLine: [{
        data: []
      }],
    }
  },
  created() {
    this.map = null
    this.mapStyle = this.getMapStyle
    this.popup = null
    this.mainHours = Object.keys(get15HoursMap())
    this.selectedHours = Object.keys(getMiniChartMap())
  },
  async mounted() {
    this.mapStyle = this.getMapStyle
    console.log('CONF DATA: ', this.configurationData)
    console.log('MERGED CONF DATA: ', this.configurationData)
    //setTimeout(()=>{this.onRefreshData()}, 1000)
    this.mergeConfigurationData()
    this.setMiniChart()
  },
  methods: {
    ...mapActions({
      getAiKitSiteData: 'safetyModule/getAiKitSiteData',
      getTrafficSiteMapConfiguration: 'safetyModule/getTrafficSiteMapConfiguration'
    }),
    onPickChanged(xaxis) {
      console.log('On pick changed', xaxis.min, xaxis.max)
      let startValue = Math.round(xaxis.min)
      let stopValue = Math.round(xaxis.max)

      console.log('On pick changed start stop: ', startValue, stopValue)
      let hoursMap = getMiniChartMap()
      let keys = Object.keys(hoursMap)
      console.log('On pick changed keys: ', keys)
      let includedValues = keys.slice(startValue, stopValue + 1)
      console.log('On pick changed Included values: ', includedValues)
      this.selectedHours = includedValues
      this.mergeConfigurationData()

    },
    onDaysChanged(value) {
      this.trafficDays = value
      this.mergeConfigurationData()
      this.setMiniChart()
    },
    setMiniChart() {
      if (this.serverConfigurationData && this.hourlyReport) {
        //let aiKitReport = getMapData1()
        this.chartData = {}
        if (this.hourlyReport[this.trafficDays]) {
          Object.keys(this.hourlyReport[this.trafficDays]).forEach(time => {
            let aiKitItem = this.hourlyReport[this.trafficDays][time]
            let sum = 0
            Object.keys(aiKitItem).forEach(aiKitLabel => {
              Object.keys(aiKitItem[aiKitLabel]).forEach(classType => {
                sum += aiKitItem[aiKitLabel][classType]
              })
            })
            this.chartData[time] = sum
          })
        }

        console.log('ChartData', this.chartData)

        let merged = {...getMiniChartMap(), ...this.chartData};

        console.log('ChartData merged', merged)

        let series = JSON.parse(JSON.stringify(this.seriesLine))
        let chartOptions = JSON.parse(JSON.stringify(this.chartOptionsLine))
        series[0].data = Object.values(merged)

        this.seriesLine = series

        console.log('ChartData categ: ', chartOptions.xaxis.categories)
        console.log('ChartData categ: ', this.seriesLine)
      }
    },
    mergeConfigurationData() {
      if (this.serverConfigurationData && this.hourlyReport) {
        //let aiKitReport = getMapData1()
        this.configurationData = JSON.parse(JSON.stringify(this.serverConfigurationData));
        this.sumOfEventsByClassType = {}
        this.sumOfEvents = 0
        let filteredAiKitData = {}
        if (this.hourlyReport[this.trafficDays]) {
          Object.keys(this.hourlyReport[this.trafficDays]).forEach(time => {
            if (this.selectedHours.includes(time)) {
              let aiKitItem = this.hourlyReport[this.trafficDays][time]
              Object.keys(aiKitItem).forEach(aiKitLabel => {
                Object.keys(aiKitItem[aiKitLabel]).forEach(classType => {
                  if (filteredAiKitData[aiKitLabel] === undefined) {
                    filteredAiKitData[aiKitLabel] = {}
                  }
                  if (filteredAiKitData[aiKitLabel][classType] === undefined) {
                    filteredAiKitData[aiKitLabel][classType] = 0
                  }
                  filteredAiKitData[aiKitLabel][classType] += aiKitItem[aiKitLabel][classType]
                })
              })
            }
          })
        }
        console.log('Filtered data: ', filteredAiKitData)
        this.sumOfEventsByClassType = {}
        this.sumOfEvents = 0
        this.configurationData.items.forEach(item => {
          let sum = 0;
          item.data = {
            name: item.wayLabel,
            events: sum,
            classTypes: {},
            dates: 'Dates'
          }
          if (filteredAiKitData[item.wayKitLabel]) {
            console.log('DATA EXISTS', filteredAiKitData[item.wayKitLabel])
            Object.keys(filteredAiKitData[item.wayKitLabel]).forEach(key => {
              if (this.classTypesFilter.includes(key)) {
                item.data.events += filteredAiKitData[item.wayKitLabel][key]
                item.data.classTypes[key] = filteredAiKitData[item.wayKitLabel][key]
                if (this.sumOfEventsByClassType[key]) {
                  this.sumOfEventsByClassType[key] = this.sumOfEventsByClassType[key] + filteredAiKitData[item.wayKitLabel][key]
                } else {
                  this.sumOfEventsByClassType[key] = filteredAiKitData[item.wayKitLabel][key]
                }

                this.sumOfEvents += filteredAiKitData[item.wayKitLabel][key]
              }
            })
          }
        })
        this.sumOfEvents = Math.round(this.sumOfEvents)
        console.log('New configuration data1111: ', this.configurationData)
      }
    },
    onClassTypeChanged(types) {
      console.log('On class type changed: ', types)
      this.classTypesFilter = types
      this.mergeConfigurationData()
    },
    onMarkerClicked(item) {
      console.log('On marker clicked 11', item)
      if (this.selectedItem === item) {
        this.selectedItem = null
      } else {
        this.selectedItem = item
        this.clickedMarker = true
      }

      console.log('On marker clicked 11', this.selectedItem)

      if (this.selectedItem !== null) {
        console.log('On marker clicked 11', this.selectedItem)
        let zoomPoint = {
          center: this.selectedItem.location.coordinates,
          zoom: this.configurationData.zoom,
          pitch: 0,
          bearing: 0
        };
        this.map.flyTo(zoomPoint)
      } else {
        this.zoomMapToSelectedSiteOrProject(true)
      }

    },
    onMapClicked(event) {
      console.log('On map clicked', event)
      setTimeout(() => {
        if (!this.selectedItem) {
          this.zoomMapToSelectedSiteOrProject(true)
        }
      }, 100)
    },
    onMapLoaded(map) {
      console.log('On map loaded')
      let obj = this
      this.map = map.map;
      obj.mapRotation = this.map.getBearing()
      this.map.scrollZoom.disable();
      this.map.on('zoom', () => {
        this.zoom = this.map.getZoom();
      });

      this.map.on('rotate', () => {
        console.log('Map rotation', this.map.getBearing())
        obj.mapRotation = this.map.getBearing()
      })

      this.map.on('click', (e) => {
        console.log('Clicked on map: ', e.lngLat)
        if (!this.clickedMarker) {
          this.selectedItem = null
        }
        this.clickedMarker = false
        //this.zoomMapToSelectedSiteOrProject(true)
      })

      this.addLayers()
      console.log('Get map conf in map: ', this.configurationData)
      this.zoomMapToSelectedSiteOrProject(true)

      this.map.resize()
    },
    changeStyle(style) {
      this.mapStyle = style

      setTimeout(() => {
        this.addLayers()
      }, 300)

    },
    zoomMapToSelectedSiteOrProject(animate) {

      if (this.selectedDataDevice) {
        //Zoom to selected site
        let zoomPoint = {
          center: this.selectedDataDevice.location.coordinates,
          zoom: 18,
          pitch: 0,
          bearing: 0
        };
        if (this.configurationData && this.configurationData.center && this.configurationData.zoom) {
          zoomPoint = {
            center: this.configurationData.center,
            zoom: this.configurationData.zoom,
            pitch: 0,
            bearing: 0
          };
        }

        this.map.jumpTo(zoomPoint)
      } else {
        const startPoint = {
          center: this.mapCenter,
          zoom: 13,
          pitch: 0,
          bearing: 0
        };
        this.map.jumpTo(startPoint)
      }
    },
    addLayers() {
      try {
        if (this.map.getLayer("draw-readonly")) {
          this.map.removeLayer("draw-readonly");
        }

        if (this.map.getSource("draw-readonly")) {
          this.map.removeSource("draw-readonly");
        }
      } catch (err) {
        console.log('Draw layer not added')
      }
      this.map.addLayer({
        id: 'draw-readonly',
        type: 'line',
        source: {
          type: 'geojson',
          data: this.configurationData.drawings
        },
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-color": "#ffffff",
          "line-width": 8
        }
      })
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      tableData: state => state.safetyModule.tableData
    }),
    ...mapGetters({
      getMapStyle: 'getMapStyle'
    }),
  }
}
</script>

<style scoped>

</style>