export default () => ({
  allSites:[],
  allDevices: [],
  selectedProjectsDevices: [],
  selectedDataDevice: null,
  selectedProject: null,
  searchQueryData:{query:'', sort:{icon: 'sort-alphabetical-ascending', text: 'Alphabetical order'}},
  searchProjectQuery: '',
  tableData:[],
  fileOperationsTableData:[],
  tableHeaderData:{
    activeSorted: {
      field: '',
      order: ''
    },
    activeFilterField:'',
    filters:{risk: [], pet: null, dca: null, date: null, time:null, ttc:null, kineticEnergy:null, userTypes:null}
  }
});
