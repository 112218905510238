<template>
  <div class="box card-box m-0 p-0" style="border-radius: 16px">
    <div class="columns m-0 p-3">
      <div class="column m-0 p-0">
        <div class="is-size-6 has-text-weight-semibold">{{ data && data.name ? data.name : 'Undefined' }}</div>
      </div>
    </div>
    <hr class="solid-thin mt-0 mb-1">
    <div v-if="data && data.classTypes">
      <div class="columns m-0 p-0 pl-3 pr-3" v-for="key in Object.keys(data.classTypes)" :key="key">
        <div class="column is-flex p-0 m-0 mt-1">
          <b-icon :icon="getItem(key).icon" style="font-size: 20px; width: 20px; height: 20px; color: #737373"
                  size="is-small"></b-icon>
          <div style="font-size: 14px" class="ml-1">{{ key }}</div>
        </div>
        <div class="column is-flex p-0 m-0">
          <div class="ml-auto has-text-weight-semibold" style="font-size: 14px">{{ getItem(key).count.toFixed(0) }}</div>
        </div>
      </div>
    </div>
    <hr class="solid-thin mt-3 mb-1">
    <div class="columns m-0 p-0 pl-3 pr-3 pb-3 pt-2 is-align-items-center">
      <div class="column is-8 m-0 p-0">
        <div class="is-size-6 has-text-weight-semibold">{{data && data.dates ? data.dates : 'Undefined'}}</div>
      </div>
      <div class="column is-4 m-0 p-0" v-if="data">
        <div class="status ml-auto" style="font-size: 16px">{{ data.events.toFixed(0) }}</div>
      </div>
    </div>
    <!--    <b-button style="margin-top: 32px" type="is-primary" expanded>View details</b-button>-->
  </div>
</template>

<script>
import {getClassTypes} from "@/utils/utils";

export default {
  name: "tmc-popup-content",
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    getItem(key) {
      let availableItems = getClassTypes()
      let index = availableItems.findIndex(itm => itm.name === key)
      if (index !== -1) {
        let item = JSON.parse(JSON.stringify(availableItems[index]))
        item.count = this.data.classTypes[key]
        return item
      }

      return {
        name: '',
        icon: '',
        count: '-'
      }
    }
  }
}
</script>

<style scoped>
.status {
  color: white;
  height: 24px;
  width: fit-content;
  border-radius: 4px;
  background: #B1B1B1;
  padding: 2px 8px;
}
</style>