<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div style="padding-bottom: 300px; min-height: 300px">
      <view-guard :permission="'safety:get-site-operations-report'">
        <tmc-map-operations
            v-if="this.selectedDataDevice && this.selectedDataDevice.tmcOperationsReport && this.selectedDataDevice.tmcMapConfiguration"
            :server-configuration-data="this.selectedDataDevice.tmcMapConfiguration"
            :report="this.selectedDataDevice.tmcOperationsReport"></tmc-map-operations>
      </view-guard>
      <files-operations></files-operations>
    </div>
  </div>
</template>

<script>

import FilesOperations from "@/modules/safety-module/components/operations/files-operations.vue";
import TmcMapOperations from "@/modules/safety-module/components/operations/tmc-map-operations.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "operations-tab",
  components: {ViewGuard, TmcMapOperations, FilesOperations},
  data() {
    return {
      isLoading: false,
    }
  },
  async mounted() {},
  methods: {
    ...mapActions({
      getTrafficSiteMapConfiguration: 'safetyModule/getTrafficSiteMapConfiguration',
      getOperationsReport: 'safetyModule/getOperationsReport',
    }),
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice
    }),
    ...mapGetters({
      userRole: 'getUserRole'
    }),
  }
}
</script>

<style scoped>

</style>