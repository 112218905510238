
export default {
    token: (state) => {
        return 'Bearer ' + state.user.signInUserSession.idToken
    },
    getUserFullName: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['name']}`
    },
    getUserRole: (state) => {
        return state.user.signInUserSession.idToken.payload['custom:role']
    },
    getUserId: (state) => {
        return state.user.signInUserSession.idToken.payload['sub']
    },
    getUserEmail: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['email']}`
    },
    getUserPhone: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['phone_number']}`
    },
    isAuth: (state) => {
        return !!state.user
    },
    getMe: (state) => {
        return state.me
    },
    getMapStyle: (state) => {
        return state.mapStyle
    },
    getProjects: (state) => {
        return state.allProjects
    },
}
