<template>
  <MglMarker ref="marker" v-if="item.location.coordinates"
             @update:coordinates="updateCoordinates"
             :draggable="drawMode === 'move'"
             :coordinates="item.location.coordinates"
             @click="markerClicked">
    <div slot="marker">
      <div :class="isSelected ? item.selected : item.marker" v-on:mousedown="mouseDown" v-on:mouseup="mouseUp" :key="isSelected"
           :style="{'rotate': rotationRepresentation+'deg'}"
           v-on:mousedown.right="mouseDownRight" v-on:mouseup.right="mouseUpRight">
        <div :class="getIncidentsClass(item.marker)" :style="{'rotate': -rotationRepresentation+'deg'}">-</div>
        <div :class="getWayClass(item.marker)" style="text-stroke: 1px black" :style="{'rotate': -rotationRepresentation+'deg'}">{{item.wayLabel}}
        </div>
      </div>
    </div>
  </MglMarker>
</template>

<script>
import {mapGetters} from "vuex";
import {MglMap, MglMarker} from "vue-mapbox";

export default {
  name: "configuration-marker",
  components: {MglMarker},
  props: {
    item: {
      type: Object,
      required: true
    },
    drawMode: {
      type: String,
      required: false
    },
    mapRotation: {
      type: Number,
      default: 0
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      //isSelected: false,
      rotation: this.item.rotation,
      rotationRepresentation: this.item.rotation,
      started: false,
      direction: false,
      showPopup: false
    }
  },
  mounted() {
    console.log('My marker device: ', this.item)
    console.log('Marker rotation: ', this.rotation)
    console.log('Marker device rotation: ', this.item.rotation)
    console.log('Marker location: ', this.item.location.coordinates)
    this.rotationRepresentation = this.rotation - this.mapRotation
    console.log('Marker device rotation VIEW: ', this.rotationRepresentation)
  },
  methods: {
    countDownTimer() {
      if (this.started) {
        this.direction ? this.rotation-- : this.rotation++;
        if (this.rotation < -180) {
          this.rotation = 180
        } else if (this.rotation > 180) {
          this.rotation = -180
        }

        this.rotationRepresentation = this.rotation - this.mapRotation

        console.log('Rotation:', this.rotation)
        setTimeout(() => {
          this.countDownTimer()
        }, 20)
      }
    },
    markerClicked(event) {
      console.log('Marker clicked my marker:', event, this.drawMode)
      //this.isSelected = !this.isSelected
      if (this.drawMode === null) {
        this.$emit('onMarkerClicked', event)
      }
    },
    onClosePopup() {
      console.log('On close popup')
    },
    mouseDown(event) {
      console.log('Mouse down:', event)
      if (this.drawMode === 'rotate') {
        this.started = true
        this.direction = false
        this.countDownTimer()
      }

    },
    mouseUp(event) {
      console.log('Mouse up:', event)
      if (this.drawMode === 'rotate') {
        this.started = false
        this.$emit('onRotateFinished', {item: this.item, rotation: this.rotation})
      }
    },
    mouseDownRight(event) {
      console.log('Mouse down:', event)
      if (this.drawMode === 'rotate') {
        this.started = true
        this.direction = true
        this.countDownTimer()
      }
    },
    mouseUpRight(event) {
      console.log('Mouse up:', event)
      if (this.drawMode === 'rotate') {
        this.started = false
        this.$emit('onRotateFinished', {item: this.item, rotation: this.rotation})
      }
    },
    updateCoordinates(event) {
      console.log('Update coordinates:', event)
      let ev = {
        item: this.item,
        coordinates: {lng: event[0], lat: event[1]}
      }
      this.$emit('onUpdateCoordinates', ev)
    },
    getIncidentsClass(markerClass) {
      if (markerClass === 'arrow-right') {
        return 'incidents-number-right'
      } else if (markerClass === 'arrow-left') {
        return 'incidents-number-left'
      }else if(markerClass === 'arrow-straight'){
        return 'incidents-number'
      }
    },
    getWayClass(markerClass) {
      if (markerClass === 'arrow-right') {
        return 'way-number-right'
      } else if (markerClass === 'arrow-left') {
        return 'way-number-left'
      }else if(markerClass === 'arrow-straight'){
        return 'way-number'
      }
    }
  },
  computed: {
    ...mapGetters({
      getMapStyle: 'getMapStyle'
    }),
  },
  watch: {
    mapRotation() {
      console.log('Map rotate: ', this.mapRotation)
      this.rotationRepresentation = this.rotation - this.mapRotation
      console.log('Map rotate REPRESENTATION: ', this.rotationRepresentation)
    },
    isSelected() {
      console.log('Is selected: ', this.isSelected)
      /*      setTimeout(()=>{
              this.showPopup = this.isSelected
            }, 100)*/

    }
  },
}
</script>

<style scoped>

</style>