<template>
  <div id="chartholder" ref="chartholder" class="has-text-centered" style="margin-top: -15px"></div>
</template>

<script>
import * as d3 from 'd3'
import d3X3d from 'd3-x3d'
import {mapState} from "vuex";

export default {
  name: "matrix-chart",
  props: {
    chartData: {
      type: Object
    }
  },
  data() {
    return {refresh: false}
  },
  mounted() {
    console.log('test chart data', this.chartData)

    /*    let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://d3js.org/d3.v7.min.js')
        document.head.appendChild(recaptchaScript)*/

    let recaptchaScript1 = document.createElement('script')
    recaptchaScript1.setAttribute('src', 'https://x3dom.org/release/x3dom-full.js')
    document.head.appendChild(recaptchaScript1)

    this.refreshChart()
  },
  methods: {
    refreshChart() {
      let chartHolder = d3.select('#chartholder');
      console.log('Chart data', this.chartData)
      let matrixChartData = []

      if(this.chartData){
        Object.keys(this.chartData).forEach(leadKey =>{
          let item = {
            key: leadKey.replace('_', ' '),
            values: []
          }

          Object.keys(this.chartData[leadKey]).forEach(followKey =>{
            let sum = this.chartData[leadKey][followKey].severe + this.chartData[leadKey][followKey].moderate + this.chartData[leadKey][followKey].low
            if(sum > 0) {
              item.values.push({
                key: followKey.replace('_', ' '), value: sum
              })
            }
          })
          let sumOfValues = 0
          item.values.forEach(itm => {
            sumOfValues += itm.value
          })
          if(sumOfValues > 0){
            matrixChartData.push(item)
          }

        })
      }

      matrixChartData.forEach(item => {
        if(item.values && item.values.length > 0){
          item.values.reverse()
        }
      })

      console.log('Matrix chart data: ', matrixChartData)

      // Generate some data
      let myData = [
        {
          key: "Car",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Passenger",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Bus",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Light commercial",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Heavy commercial",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Adult",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Student",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Child",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Bike",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
        {
          key: "Motorcycle",
          values: [
            {key: "Car", value: 10},
            {key: "Passenger", value: 12},
            {key: "Bus", value: 9},
            {key: "Light commercial", value: 15},
            {key: "Heavy commercial", value: 17},
            {key: "Adult", value: 8},
            {key: "Student", value: 7},
            {key: "Child", value: 5},
            {key: "Bike", value: 8},
            {key: "Motorcycle", value: 9}
          ]
        },
      ];

      // Declare the chart component
      console.log('D3d chart', d3X3d)
      let myChart = d3X3d.chart.barChartMultiSeries();
      myChart.width(400);
      myChart.height(400)

      myChart.colors(['#E68600'])
      console.log('D3d chart 1', myChart)
      //console.log('D3d chart 11', myChart.viewPosition())

      // Attach chart and data to the chart holder
      chartHolder
          .datum(matrixChartData)
          .call(myChart);
    }
  },
  watch: {
    chartData(oldValue, newValue) {
      console.log('Chart data changed')
      if (oldValue !== newValue) {
        this.refreshChart()
      }
    }
  },
  computed: {
        ...mapState({
              selectedDataDevice: state => state.safetyModule.selectedDataDevice,
            }),
      }
}
</script>

<style scoped>

</style>