<template>
  <div>
    <div id="map-container-heat" style="height: 680px" class="mb-6">
      <MglMap
          ref="mapboxMap"
          container="map-details-heat"
          :accessToken="$env.VUE_APP_ACCESS_TOKEN"
          :center.sync="mapCenter"
          :zoom.sync="mapZoom"
          :mapStyle="mapStyle"
          @load="onMapLoaded">
        <nav class="listing-group-top-left">
          <section style="padding: 16px; background-color: rgba(255,255,255,0.85); border-radius: 8px;">
            <div class="columns">
              <div class="column p-0 m-0 ml-1 is-one-quarter">
                <b-icon icon="circle-medium" :class="getRatingClass('Severe')"></b-icon>
              </div>
              <div class="column p-0 m-0 pl-1 pr-2">Severe</div>
            </div>
            <div class="columns">
              <div class="column p-0 m-0 ml-1 is-one-quarter">
                <b-icon icon="circle-medium" :class="getRatingClass('Moderate')"></b-icon>
              </div>
              <div class="column p-0 m-0 pl-1 pr-2">Moderate</div>
            </div>
            <div class="columns">
              <div class="column p-0 m-0 ml-1 is-one-quarter">
                <b-icon icon="circle-medium" :class="getRatingClass('Low')"></b-icon>
              </div>
              <div class="column p-0 m-0 pl-1 pr-2">Low</div>
            </div>
          </section>
        </nav>
        <map-style-menu @onStyleChanged="changeStyle"></map-style-menu>
      </MglMap>
    </div>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import {MglMap} from "vue-mapbox";
import {bbox, lineString} from "@turf/turf";
import MapStyleMenu from "@/components/map-style-menu/map-style-menu.vue";
import {getRiskRatingClass} from "@/utils/utils";
import {mapGetters, mapState} from "vuex";

export default {
  name: "heat-map",
  components: {
    MapStyleMenu,
    MglMap
  },
  data() {
    return {
      mapActive: true,
      mapCenter: [144.94444, -37.80193],
      mapZoom: 10,
      mapHeight: '400px',
      geoJsonSourceHeat: {},
      geoJsonPathHeat: {},
      layerHeat: {},
      layerCircle: {},
      sourceIdHeat: 'source_id_heat',
      mapStyle: '',
      minPet: -1,
      maxPet: -1
    }
  },
  created() {
    this.map = null
    this.mapStyle = this.getMapStyle
    this.popup = null
    this.initMapLayers()

    console.log('DATA: ', this.tableData)
  },
  methods: {
    changeStyle(style) {
      this.mapStyle = style
      setTimeout(() => {
        this.addLayersToMap()
      }, 300)

    },
    getRatingClass(property) {
      return getRiskRatingClass(property)
    },
    initMapLayers() {
      let mapData = []

      if (this.tableData.length > 0) {
        let item = this.tableData[0]
        this.minPet = item.min_pet
        this.maxPet = item.max_pet
      }

      this.tableData.forEach(incident => {
        if (incident && incident.conflictPoint) {
          mapData.push(incident.conflictPoint)
        }
      })

      this.geoJsonPathHeat = {
        'type': 'FeatureCollection',
        'features': mapData
      }

      this.geoJsonSourceHeat = {
        'type': 'geojson',
        'data': this.geoJsonPathHeat
      }

      this.layerHeat = {
        'id': 'heat_id',
        'source': this.sourceIdHeat,
        'type': 'heatmap',
        'maxzoom': 22,
        'paint': {
          // Increase the heatmap weight based on frequency and property heat_intensity
          'heatmap-weight': {
            property: 'heat_intensity',
            type: 'exponential',
            stops: [
              [1, 0],
              [100, 1]
            ]
          },
          'heatmap-intensity': {
            stops: [
              [18, 1],
              [20, 3]
            ]
          },
          // assign color values be applied to points depending on their density
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(201,172,152,0)',
            0.2,
            'rgb(232,177,121)',
            0.4,
            'rgb(255,107,1)',
            0.6,
            'rgb(222,52,52)',
            0.8,
            'rgb(203,4,4)'
          ],
          // increase radius as zoom increases
          'heatmap-radius': {
            stops: [
              [18, 15],
              [20, 20]
            ]
          },
          // decrease opacity to transition into the circle layer
          'heatmap-opacity': {
            default: 1,
            stops: [
              [18, 1],
              [20, 0]
            ]
          }
        }
      }
      this.layerCircle = {
        id: 'incidents-point',
        type: 'circle',
        source: this.sourceIdHeat,
        minzoom: 19,
        paint: {
          // increase the radius of the circle as the zoom level and heat_intensity value increases
          'circle-radius': {
            property: 'heat_intensity',
            type: 'exponential',
            stops: [
              [{zoom: 19, value: 1}, 3],
              [{zoom: 19, value: 100}, 5],
              [{zoom: 21, value: 1}, 7],
              [{zoom: 21, value: 100}, 10]
            ]
          },
          'circle-color': {
            property: 'heat_intensity',
            type: 'exponential',
            stops: [
              [10, 'rgb(62,142,208)'],
              [40, 'rgb(239,167,13)'],
              [90, 'rgb(255,0,0)']
            ]
          },
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          'circle-opacity': {
            stops: [
              [19, 0],
              [20, 1]
            ]
          }
        }
      }
    },
    onMapLoaded(map) {
      if (this.map == null) {
        this.map = map.map;

        console.log('Map loaded: ', this.map)
        this.map.on('zoom', () => {
        });

        this.map.on('mousemove', function (e) {
        });

        this.map.on('mouseenter', 'incidents-point', (event) => {
          this.map.getCanvas().style.cursor = 'pointer';
          console.log('Mouse enter: ', event)
          let pet = event.features[0].properties.pet ? event.features[0].properties.pet >= 0.0 ? event.features[0].properties.pet : 'N/A' : 'N/A'
          this.popup = new Mapbox.Popup({className: "black-popup"})
              .setLngLat(event.features[0].geometry.coordinates)
              .setHTML(`<div class="m-2 is-flex is-size-7" style="padding-left: 8px">
                        PET: <strong style="margin-right: 8px; color: white"> ` + pet +
                  `</strong> Crash Type: <strong style="color: white"> ` + event.features[0].properties.dca + `</strong>
                        </div>`)
              .addTo(this.map);
        });

        this.map.on('mouseleave', 'incidents-point', () => {
          this.map.getCanvas().style.cursor = '';
          if (this.popup) {
            this.popup.remove()
          }
        });

        this.map.on('click', 'incidents-point', (event) => {
          console.log('Clicked: ', event.features[0].geometry.coordinates)
          this.loadEvent(event.features[0].properties.id)
        });

      }

      this.addLayersToMap()
      this.map.resize()

    },
    addLayersToMap() {
      this.map.addSource(this.sourceIdHeat, this.geoJsonSourceHeat)
      this.map.addLayer(this.layerHeat)
      this.map.addLayer(this.layerCircle)

      setTimeout(() => {
        this.map.resize()
        /*        this.map.setPitch(60)
                this.map.setBearing(120)*/
        let points = this.geoJsonPathHeat.features.map(feature => feature.geometry.coordinates)
        console.log('Points', this.geoJsonPathHeat.features)
        if(points && points.length > 1){
          try {
            let line = lineString(points);
            let bb = bbox(line);
            this.map.fitBounds(bb, {padding: 180})
            console.log('On fit bounds: ', bb)
          } catch (e) {
            console.error('Fit bounds error:', e)
          }
        }



      }, 500)
    },
    loadEvent(eventId) {
      console.log('Event:', eventId)
      const params = {
        eventId: eventId,
        projectId: this.selectedProject._id,
        dataDeviceId: this.selectedDataDevice.id
      }

      this.$router.push({name: 'event-details', params: params})
    },
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      tableData: state => state.safetyModule.tableData
    }),
    ...mapGetters({
      getMapStyle: 'getMapStyle'
    }),
  }
}
</script>

<style scoped>

</style>