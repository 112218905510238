<template>
  <div class="pl-5 pr-5 pt-4 pb-1">
    <div class="is-size-5 has-text-weight-semibold">Speed distribution</div>
    <hr class="solid-thin mt-2 mb-1">
    <div id="chart">
      <apex-chart type="bar" height="300" :options="chartOptions" :series="series"></apex-chart>
    </div>
  </div>
</template>

<script>
import {getFormatted15Minutes, getSpeedDistributionData} from "@/utils/utils";

export default {
  name: "speed-distribution-chart",
  data(){
    return {
      series: [{
        name: 'Speed km/h',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        },
/*        yaxis: {
          title: {
            text: 'Speed km/h'
          }
        },*/
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands"
            }
          }
        }
      },
    }
  },
  created() {
    let formattedResponse = getSpeedDistributionData()
    let series = JSON.parse(JSON.stringify(this.series))
    let chartOptions = JSON.parse(JSON.stringify(this.chartOptions))
    series[0].data = Object.values(formattedResponse)
    chartOptions.xaxis.categories = Object.keys(formattedResponse)

    this.series = series
    this.chartOptions = chartOptions
  }
}
</script>

<style scoped>

</style>