<template src="./incident-data.html"></template>

<script>
import {getRiskRatingClass} from "@/utils/utils";
import SiteCharts from "@/modules/safety-module/components/site-charts/site-charts.vue";
import {mapGetters, mapState} from "vuex";
import IncidentsTable from "@/modules/safety-module/components/incidents-table/incidents-table.vue";

export default {
  name: "incident-data",
  components: {IncidentsTable, SiteCharts},
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
  },
  methods: {
    getRiskColor(risk){
      return getRiskRatingClass(risk)
    },
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      tableHeaderData: state => state.safetyModule.tableHeaderData,
    }),
    ...mapGetters({
      averageValues: 'safetyModule/getAverageValues',
    }),
  }
}
</script>

<style scoped lang="scss" src="./incident-data.scss"></style>