import Vue from 'vue'
import VueRouter from 'vue-router'
import {Auth} from 'aws-amplify'
import notFound from "@/views/not-found/not-found.vue"
import startPage from "@/views/start-page/start-page.vue"
import forbiddenPage from "@/views/forbidden/forbidden-page.vue";
import store from "@/store";
import Utils from "@/utils/utils";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'start-page',
        meta: {
            title: 'Welcome',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: startPage
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        meta:{
            title: 'Forbidden',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: forbiddenPage
    },
    {
        path: '*',
        name: 'not-found',
        meta:{
            title: 'Not found',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: notFound
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
/**
 * Auth check if user is logged in
 */
router.beforeEach(async (to, from, next) => {
    //Disable check just for these routes
    console.log('Router next', to)

    if (to.meta.isUnrestricted) {
        //NOT Authorized
        next()
    } else {
        //Authorized
        await Auth.currentAuthenticatedUser().then(async(user) => {
            console.log('Route user: ', user)
            let permNames = []
            let appNames = []
            if (store.getters.getMe === null) {
                await store.dispatch('getMe')
            }
            if (store.getters.getMe && store.getters.getMe.permissions && store.getters.getMe.permissions.length > 0) {
                permNames = store.getters.getMe.permissions.map(perm => perm.name)
                appNames = store.getters.getMe.apps.map(app => app.name)
            }

            console.log('Perm names', permNames)
            console.log('App names', appNames)

            if(appNames.length > 0){
                if(appNames.includes(Utils.appName)){
                    console.log('Accessing app...')
                }else{
                    console.log('Navigation guard: Can\'t access this app')
                    await router.push('/')
                    return
                }
            }

            if (permNames.length > 0) {
                if (permNames.includes(to.meta.permission)) {
                    console.log('Calling next')
                    next()
                } else {
                    console.log('Navigation guard: Can\'t access this page')
                    await router.replace({name: 'forbidden'})
                }
            } else {
                console.log('Navigation guard: Provide user roles')
            }
        }).catch((error) => {
            //NOT Authorized, redirect to log in
            router.push('/')
            console.log('Navigation guard: ', error)
        });
    }
})
export default router
