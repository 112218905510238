<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div v-if="this.aiKitSiteConf && this.aiKitSiteConf.length > 0">
      <site-configurator-modal ref="confModal" @onCreateWay="onCreateWay"></site-configurator-modal>
      <configurator-map-input ref="confMapInput"
                              :configurationData="configurationData"
                              :selected-item="selectedItem"
                              @onMapInputClicked="onMapClicked"
                              @onCoordinatesUpdated="onCoordinatesUpdated"
                              @onDrawingsUpdated="onDrawingsUpdated"
                              @onMarkerClicked="onMarkerClicked"
                              @onDeleteItem="onDeleteItem"
                              @onRotationChanged="onRotationChanged"></configurator-map-input>
      <div class="mb-6 mt-4 is-flex">
        <view-guard :permission="'safety:update-map-configuration'" v-if="configurationData && configurationData._id">
          <b-button type="is-primary" @click="onUpdateConfiguration"
                    icon-left="pencil">
            Update
          </b-button>
        </view-guard>
        <view-guard :permission="'safety:delete-map-configuration'" v-if="configurationData && configurationData._id">
          <b-button class="ml-1" type="is-danger" @click="confirmDelete"

                    icon-left="delete">
            DELETE
          </b-button>
        </view-guard>
        <view-guard :permission="'safety:add-map-configuration'" v-else-if="configurationData">
          <b-button type="is-primary" @click="onAddConfiguration"
                    icon-left="plus">
            Add
          </b-button>
        </view-guard>
        <view-guard :permission="'safety:create-site-operations-report'"
                    v-if="configurationData && configurationData._id" class="ml-auto">
          <b-button type="is-primary" @click="onCreateReport"
                    icon-left="plus">
            Create TMC report
          </b-button>
        </view-guard>
      </div>
    </div>
    <div class="card" style="margin-bottom: 100px" v-else>
      <div class="card-content p-1">
        <div style="height: 160px" class="is-flex is-align-items-center">
          <div class="is-flex is-flex-direction-column is-align-items-center ml-auto mr-auto">
            <div>
              <b-icon icon="file-cancel-outline"></b-icon>
            </div>
            <div>There are no available data from AI Kit</div>
            <b-button type="is-primary" class="mt-4" v-if="isLoadingDataAllowed()" @click="loadAllData">Load AI Kit Data</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfiguratorMapInput from "@/modules/safety-module/components/configurator-map-input/configurator-map-input.vue";
import SiteConfiguratorModal
  from "@/modules/safety-module/components/site-configurator-modal/site-configurator-modal.vue";
import {mapActions, mapState} from "vuex";
import {isAllowed} from "@/utils/utils";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "site-configurator",
  components: {ViewGuard, SiteConfiguratorModal, ConfiguratorMapInput},
  data() {
    return {
      configurationData: null,
      aiKitSiteConf: null,
      selectedItem: null,
      isLoading: false
    }
  },
  async created() {
  },
  async mounted() {
  },
  methods: {
    ...mapActions({
      getAiKitSiteConfiguration: 'safetyModule/getAiKitSiteConfiguration',
      getAiKitReport: 'safetyModule/getAiKitReport',
      createTrafficSiteMapConfiguration: 'safetyModule/createTrafficSiteMapConfiguration',
      getTrafficSiteMapConfiguration: 'safetyModule/getTrafficSiteMapConfiguration',
      updateTrafficSiteMapConfiguration: 'safetyModule/updateTrafficSiteMapConfiguration',
      deleteTrafficSiteMapConfiguration: 'safetyModule/deleteTrafficSiteMapConfiguration',
      createUpdateTMCReport: 'safetyModule/createTMCReport',
      setSelectedDataDevice: 'safetyModule/setSelectedDataDevice',
    }),
    async loadAllData(){
      if(this.isLoadingDataAllowed() && this.selectedDataDevice){
        this.isLoading = true
        let aiKitConfResponse = await this.getAiKitSiteConfiguration(this.selectedDataDevice.id);
        if (aiKitConfResponse.error) {
          this.showToast('AI Kit: ' + aiKitConfResponse.error, 'is-danger')
          this.isLoading = false
          return
        } else if(aiKitConfResponse && aiKitConfResponse.length === 0){
          this.showToast('No AI Kit data for this device', 'is-danger')
          this.isLoading = false
          return
        }else{
          this.aiKitSiteConf = aiKitConfResponse
        }
        let response = await this.getTrafficSiteMapConfiguration(this.selectedDataDevice.id)
        console.log('Get map conf: ', response)
        console.log('Get AI Kit conf conf: ', this.aiKitSiteConf)
        this.configurationData = response;
        if (this.configurationData === null) {
          this.configurationData = {
            projectId: this.selectedProject._id,
            dataDeviceId: this.selectedDataDevice.id,
            center: null,
            zoom: null,
            items: [],
            drawings: null
          }
        }

        this.isLoading = false
      }
    },
    isLoadingDataAllowed(){
      return isAllowed(this.$store.getters.getMe, 'safety:get-map-configuration')
    },
    async onAddConfiguration() {

      let mapData = this.$refs.confMapInput.getMapData()
      this.configurationData.center = [mapData.center.lng, mapData.center.lat]
      this.configurationData.zoom = mapData.zoom

      let payload = {
        projectId: this.selectedProject._id,
        dataDeviceId: this.selectedDataDevice.id,
        center: this.configurationData.center,
        zoom: this.configurationData.zoom,
        items: JSON.parse(JSON.stringify(this.configurationData.items)),
        drawings: JSON.parse(JSON.stringify(this.configurationData.drawings)),
      }

      console.log('Payload: ', payload)

      this.isLoading = true;
      let response = await this.createTrafficSiteMapConfiguration(payload)
      if (response instanceof Error) {
        this.showToast('Site map configuration:' + response.message, 'is-danger')
      } else {
        this.showToast('Site Map Configuration successfully created', 'is-success')
        this.configurationData = response
        let newSelectedDataDevice = JSON.parse(JSON.stringify(this.selectedDataDevice))
        newSelectedDataDevice.tmcMapConfiguration = this.configurationData
        await this.setSelectedDataDevice(newSelectedDataDevice)

      }
      this.isLoading = false;
    },
    async onCreateReport() {
      console.log('On create report:')
      this.isLoading = true;
      let aiKitReport = await this.getAiKitReport(this.selectedDataDevice.id)


      if (aiKitReport.error) {
        this.showToast(aiKitReport.error, 'is-danger')
      } else {
        console.log('On create report AI kit report:', aiKitReport)
        let payload = {
          projectId: this.selectedProject._id,
          dataDeviceId: this.selectedDataDevice.id,
          tmcHourlyReport: aiKitReport.hourlyReport ? aiKitReport.hourlyReport : null,
          tmcVolumeReport: aiKitReport.volumeReport ? aiKitReport.volumeReport : null
        }

        console.log('On create report Payload:', payload)
        let response = await this.createUpdateTMCReport(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.showToast('TMC report successfully created', 'is-success')
          let newSelectedDataDevice = JSON.parse(JSON.stringify(this.selectedDataDevice))
          newSelectedDataDevice.tmcOperationsReport = response
          await this.setSelectedDataDevice(newSelectedDataDevice)
        }
      }

      this.isLoading = false;

    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Are you sure you want to delete Site Map Configuration?',
        onConfirm: () => this.onDeleteConfiguration()
      })
    },
    async onDeleteConfiguration() {
      console.log('Payload delete: ', this.configurationData._id)

      this.isLoading = true;
      let response = await this.deleteTrafficSiteMapConfiguration(this.configurationData._id)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Site Map Configuration successfully deleted', 'is-success')
        if (response._id === this.configurationData._id) {
          this.configurationData = {
            projectId: this.selectedProject._id,
            dataDeviceId: this.selectedDataDevice.id,
            center: null,
            zoom: null,
            items: [],
            drawings: null
          }
          this.$refs.confMapInput.clearDrawings()
          let newSelectedDataDevice = JSON.parse(JSON.stringify(this.selectedDataDevice))
          newSelectedDataDevice.tmcMapConfiguration = this.configurationData
          await this.setSelectedDataDevice(newSelectedDataDevice)
        }
      }

      this.isLoading = false;
    },
    async onUpdateConfiguration() {

      let mapData = this.$refs.confMapInput.getMapData()
      this.configurationData.center = [mapData.center.lng, mapData.center.lat]
      this.configurationData.zoom = mapData.zoom

      let payload = {
        id: this.configurationData._id,
        projectId: this.selectedProject._id,
        dataDeviceId: this.selectedDataDevice.id,
        center: this.configurationData.center,
        zoom: this.configurationData.zoom,
        items: JSON.parse(JSON.stringify(this.configurationData.items)),
        drawings: JSON.parse(JSON.stringify(this.configurationData.drawings)),
      }

      console.log('Payload update: ', payload)

      this.isLoading = true;
      let response = await this.updateTrafficSiteMapConfiguration(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Site Map Configuration successfully updated', 'is-success')
        this.configurationData = response
        let newSelectedDataDevice = JSON.parse(JSON.stringify(this.selectedDataDevice))
        newSelectedDataDevice.tmcMapConfiguration = this.configurationData
        await this.setSelectedDataDevice(newSelectedDataDevice)
      }

      this.isLoading = false;
    },
    async onMapClicked(data) {
      console.log('On map input clicked:', data)
      let arrowMarker = ''
      let selectedMarker = ''

      if (data.drawMode === 'add-straight') {
        arrowMarker = 'arrow-straight'
        selectedMarker = 'arrow-straight-selected'
      } else if (data.drawMode === 'add-right') {
        arrowMarker = 'arrow-right'
        selectedMarker = 'arrow-right-selected'
      } else if (data.drawMode === 'add-left') {
        arrowMarker = 'arrow-left'
        selectedMarker = 'arrow-left-selected'
      }

      let newMarker = {
        id: this.configurationData.items.length,
        marker: arrowMarker,
        selected: selectedMarker,
        wayLabel: '',
        wayKitLabel: '',
        location: {
          type: "Point",
          coordinates: [data.coordinates.lng, data.coordinates.lat]
        },
        rotation: 0
      }

      this.$refs.confModal.show(true, newMarker, this.getAvailableAiKitLabels())

    },
    getAvailableAiKitLabels() {
      let availableLabels = JSON.parse(JSON.stringify(this.aiKitSiteConf))
      if (this.configurationData && this.configurationData.items && this.configurationData.items.length > 0) {
        this.configurationData.items.forEach(item => {
          let index = availableLabels.indexOf(item.wayKitLabel)
          if (index !== -1) {
            availableLabels.splice(index, 1)
          }
        })
      }

      return availableLabels
    },
    onCreateWay(newMarker) {
      this.configurationData.items.push(newMarker)
      this.$refs.confModal.show(false, null)
      localStorage.setItem('test', JSON.stringify(this.configurationData))
    },
    async onCoordinatesUpdated(data) {
      console.log('On update coordinates: ', data)
      let index = this.configurationData.items.findIndex(item => item.id === data.item.id)
      console.log('Index: ', index)
      if (index !== -1) {
        this.configurationData.items[index].location.coordinates = [data.coordinates.lng, data.coordinates.lat]
      }

      localStorage.setItem('test', JSON.stringify(this.configurationData))

    },
    onRotationChanged(data) {
      console.log('On rotation changed', data)
      let index = this.configurationData.items.findIndex(item => item.id === data.item.id)
      if (index !== -1) {
        this.configurationData.items[index].rotation = data.rotation;
      }

      localStorage.setItem('test', JSON.stringify(this.configurationData))
    },
    onDrawingsUpdated(data) {
      this.configurationData.drawings = data
      if (this.configurationData && this.configurationData.drawings && this.configurationData.drawings.features.length > 0)
        this.configurationData.drawings.features.forEach(feature => {
          delete feature.properties
        })
      localStorage.setItem('test', JSON.stringify(this.configurationData))
    },
    onMarkerClicked(item) {
      if (item !== this.selectedItem) {
        this.selectedItem = item
      } else {
        this.selectedItem = null
      }
    },
    onDeleteItem() {
      if (this.configurationData && this.configurationData.items) {
        let index = this.configurationData.items.findIndex(item => item === this.selectedItem)
        if (index !== -1) {
          this.configurationData.items.splice(index, 1)
          this.selectedItem = null
        }
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
    }),
  }
}
</script>

<style scoped>

</style>