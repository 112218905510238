
export default {
  /**
   * Save user data
   * @param state (this state)
   * @param item
   */
  saveUserData(state, item) {
    state.user = item;
  },
  setMe(state, user) {
    state.me = user
  },
  /**
   * Remove user data
   * @param state (this state)
   * @param item
   */
  removeUserData(state, item) {
    state.user = null
    state.allProjects = []
    state.me = null
  },
  setMapStyle(state, newStyle) {
    state.mapStyle = newStyle
  },
  /**
   * Update all projects after request
   * @param state (this state)
   * @param items
   */
  updateAllProjects(state, items) {
    console.log('Update all projects: ', items)
    state.allProjects = items
  },
};
