<template>
  <div>
    <div id="map-container" :style="{height: mapHeight}">
      <MglMap
          ref="mapboxMap"
          container="map-details"
          :accessToken="$env.VUE_APP_ACCESS_TOKEN"
          :center.sync="mapCenter"
          :zoom.sync="mapZoom"
          :mapStyle="mapStyle"
          @load="onMapLoaded">
        <nav class="listing-group-top-left">
          <section style="padding: 16px; background-color: rgba(255,255,255,0.85); border-radius: 8px;">
            <div class="columns">
              <div class="column p-0 m-0 ml-1 is-one-quarter">
                <b-icon icon="rectangle" :style="{color: getLeadColor()}"></b-icon>
              </div>
              <div class="column p-0 m-0">Lead user</div>
            </div>
            <div class="columns">
              <div class="column p-0 m-0 ml-1 is-one-quarter">
                <b-icon icon="rectangle" :style="{color: getFollowColor()}"></b-icon>
              </div>
              <div class="column p-0 m-0">Follow user</div>
            </div>
          </section>
        </nav>
        <map-style-menu @onStyleChanged="changeStyle"></map-style-menu>
      </MglMap>
    </div>
  </div>

</template>

<script>
import {MglMap} from "vue-mapbox";
import {bbox, bearing, lineString} from "@turf/turf";
import arrowLeadImage from '../../../../assets/arrow_blue.png';
import arrowFollowImage from '../../../../assets/arrow_red.png';
import {getRiskRatingClass} from "@/utils/utils";
import MapStyleMenu from "@/components/map-style-menu/map-style-menu.vue";

export default {
  name: "trajectory-map",
  components: {
    MapStyleMenu,
    MglMap,
  },
  data() {
    return {
      mapActive: true,
      mapCenter: [144.94444, -37.80193],
      mapZoom: 10,
      options: ['West->North', 'West->North1'],
      filterOptions: ['Date1', 'Date2', 'Date3'],
      mapHeight: '400px',
      getRiskRatingClass(property) {
        return getRiskRatingClass(property)
      },
      geoJsonSourceLead: {},
      geoJsonSourceFollow: {},
      geoJsonPathLead: {},
      geoJsonPathFollow: {},
      layerLead: {},
      layerFollow: {},
      leadArrowLayer: {},
      followArrowLayer: {},
      leadArrowSource: {},
      followArrowSource: {},
      sourceIdLead: 'lead_id',
      sourceIdFollow: 'follow_id',
      leadArrowSourceId: 'l_arrowId',
      followArrowSourceId: 'f_arrowId',
      layerHover: {},
      mapStyle: '',
      leadPoints: [],
      followPoints: [],
      leadImage: null,
      followImage: null

    }
  },
  created() {
    this.map = null
    this.mapStyle = this.$store.getters.getMapStyle
    this.initMapLayers()
  },
  methods: {
    changeStyle(style) {
      this.mapStyle = style
      setTimeout(() => {
        this.loadPath()
      }, 100)

    },
    initMapLayers() {
      this.geoJsonPathLead = {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'LineString',
              'coordinates': []
            }
          }
        ]
      }

      this.geoJsonPathFollow = {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'LineString',
              'coordinates': []
            }
          }
        ]
      }

      this.geoJsonSourceLead = {
        'type': 'geojson',
        'data': this.geoJsonPathLead
      }

      this.geoJsonSourceFollow = {
        'type': 'geojson',
        'data': this.geoJsonPathFollow
      }

      this.layerLead = {
        'id': 'route_lead',
        'source': this.sourceIdLead,
        'type': 'line',
        'paint': {
          'line-width': 3,
          'line-color': this.getLeadColor()
        }
      }

      this.layerFollow = {
        'id': 'route_follow',
        'source': this.sourceIdFollow,
        'type': 'line',
        'paint': {
          'line-width': 3,
          'line-color': this.getFollowColor()
        }
      }

      this.leadArrowSource = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }

      this.followArrowSource = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }

      this.leadArrowLayer = {
        id: 'arrows_lead',
        type: 'symbol',
        source: this.leadArrowSourceId,
        layout: {
          'icon-image': 'arrow-marker',
          'icon-size': 0.06,
          'icon-rotate': {
            type: 'identity',
            property: 'rotation',
          },
          'icon-anchor': 'center',
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true
        }
      }

      this.followArrowLayer = {
        id: 'arrows_follow',
        type: 'symbol',
        source: this.followArrowSourceId,
        layout: {
          'icon-image': 'arrow-marker-red',
          'icon-size': 0.06,
          'icon-rotate': {
            type: 'identity',
            property: 'rotation',
          },
          'icon-anchor': 'center',
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true
        }
      }
    },
    onMapLoaded(map) {
      if (this.map == null) {
        this.map = map.map;

        console.log('Map loaded: ', this.map)
        this.map.on('zoom', () => {
          this.zoom = this.map.getZoom();
        });
        this.map.on('click', function (e) {
          /*          let features = e.target.queryRenderedFeatures(e.point, {layers: ['route_lead']});
                    if (typeof e.target.getLayer('selectedRoad') !== "undefined") {
                      e.target.removeLayer('selectedRoad')
                      e.target.removeSource('selectedRoad');
                    }
                    if (!features.length) {
                      return;
                    }

                    let feature = features[0];
                    console.log(feature.toJSON());*/
          /*          e.target.addSource('selectedRoad', {
                      "type": "geojson",
                      "data": feature.toJSON()
                    });*/
          /*          e.target.addLayer({
                      "layerId": feature.id,
                      "id": "selectedRoad",
                      "type": "line",
                      "source": "selectedRoad",
                      "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                      },
                      "paint": {
                        "line-color": "red",
                        "line-width": 6
                      }
                    });*/
        });

        this.map.on('mousemove', function (e) {
          /*        var features = e.target.queryRenderedFeatures(e.point, { layers: ['route_lead'] });
                  e.target.getCanvas().style.cursor = features.length ? 'pointer' : '';*/
        });

        this.map.loadImage(
            arrowLeadImage,
            (error, image) => {
              if (error) throw error;
              this.leadImage = image
            }
        );

        this.map.loadImage(
            arrowFollowImage,
            (error, image) => {
              if (error) throw error;
              this.followImage = image
            }
        );
      }

    },
    loadPath(lp, fp) {
      console.log('On load path called: ', lp, fp)
      if (lp && fp) {
        this.leadPoints = lp
        this.followPoints = fp
      }

      let normalizedLeadPoints = this.leadPoints.map(point => {
        return [point[1], point[0]]
      })
      let normalizedFollowPoints = this.followPoints.map(point => {
        return [point[1], point[0]]
      })

      //let line = lineString(normalizedLeadPoints);
      //let bazier = bezierSpline(line,{resolution: 100});

      //let line2 = lineString(normalizedFollowPoints);
      //let bazier2 = bezierSpline(line2,{resolution: 100});

      //console.log('Bazier: ',bazier)

      this.geoJsonPathLead.features[0].geometry.coordinates = normalizedLeadPoints
      this.geoJsonPathFollow.features[0].geometry.coordinates = normalizedFollowPoints

      this.geoJsonSourceLead.data = this.geoJsonPathLead
      this.geoJsonSourceFollow.data = this.geoJsonPathFollow

      let arrowFeatures = []
      let followArrowFeatures = []

      normalizedLeadPoints.forEach((point, index) => {
        if (index > 0) {
          arrowFeatures.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: point,
            },
            properties: {
              rotation: bearing(normalizedLeadPoints[index - 1], normalizedLeadPoints[index]),
            },
          })
        }
      })

      normalizedFollowPoints.forEach((point, index) => {
        if (index > 0) {
          followArrowFeatures.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: point,
            },
            properties: {
              rotation: bearing(normalizedFollowPoints[index - 1], normalizedFollowPoints[index]),
            },
          })
        }
      })

      this.leadArrowSource.data.features = arrowFeatures
      this.followArrowSource.data.features = followArrowFeatures


      setTimeout(() => {
        if(normalizedLeadPoints && normalizedLeadPoints.length > 0){
          this.addLayersToMap()
          this.map.resize()
          try {
            let line = lineString([
              normalizedLeadPoints[0],
              normalizedLeadPoints[normalizedLeadPoints.length - 1],
              normalizedFollowPoints[0],
              normalizedFollowPoints[normalizedFollowPoints.length - 1]
            ]);
            let bb = bbox(line);
            this.map.fitBounds(bb, {padding: 100})
            console.log('On fit bounds: ', bb)
          }catch (e){
            console.error('Fit bounds error:', e)
          }
        }
      }, 500)

    },
    addLayersToMap() {
      try {
        if(!this.map.hasImage('arrow-marker')){
          this.map.addImage('arrow-marker', this.leadImage);
          this.map.addImage('arrow-marker-red', this.followImage);
        }
      } catch (e) {
        console.log('Map loading images error: ', e)
      }


      this.map.addSource(this.sourceIdLead, this.geoJsonSourceLead)
      this.map.addLayer(this.layerLead)

      this.map.addSource(this.sourceIdFollow, this.geoJsonSourceFollow)
      this.map.addLayer(this.layerFollow)

      this.map.addSource(this.leadArrowSourceId, this.leadArrowSource)
      this.map.addLayer(this.leadArrowLayer)

      this.map.addSource(this.followArrowSourceId, this.followArrowSource)
      this.map.addLayer(this.followArrowLayer)
    },
    getLeadColor() {
      return '#007cbf'
    },
    getFollowColor() {
      return '#ff1616';
    },
    setMapHeight(height) {
      this.mapHeight = `${height}px`
    }
  }
}
</script>

<style scoped>

</style>