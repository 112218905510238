<template>
  <div v-if="isAllowed">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "view-guard",
  props: {
    permission: {
      type: String
    }
  },
  computed: {
    userPermissions() {
      if (this.$store.getters.getMe && this.$store.getters.getMe.permissions && this.$store.getters.getMe.permissions.length > 0) {
        return this.$store.getters.getMe.permissions.map(perm => perm.name)
      }

      return []
    },
    isAllowed() {
      if(this.permission && this.permission.length > 0){
        return this.userPermissions.includes(this.permission)
      }else{
        return true
      }

    }
  }
}
</script>

<style scoped>

</style>