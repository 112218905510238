<template src="./template.html"></template>

<script>
import {MglMap, MglMarker, MglPopup} from 'vue-mapbox'
import SearchBox from '@/modules/safety-module/components/search-sites/search-sites-float-window.vue'
import IncidentData from "@/modules/safety-module/components/incident-data/incident-data.vue";
import TrajectoryData from "@/modules/safety-module/components/trajectory-data/trajectory-data.vue";
import {bbox, lineString} from "@turf/turf";
import {getMarkerClass, getRiskRatingClass, isAllowed} from "@/utils/utils";
import OperationsTab from "@/modules/safety-module/components/operations/operations.vue";
import MapStyleMenu from "@/components/map-style-menu/map-style-menu.vue";
import mapboxgl from 'mapbox-gl';
import geocoderControl from "@/components/geocoder-control/geocoder-control";
import TrajectoryMap from "@/modules/safety-module/components/trajectory-map/trajectory-map.vue";
import HeatMap from "@/modules/safety-module/components/heat-map/heat-map.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import SiteConfigurator from "@/modules/safety-module/components/site-configurator/site-configurator.vue";


export default {
  name: "home-screen",
  components: {
    SiteConfigurator,
    HeatMap,
    TrajectoryMap,
    MapStyleMenu,
    OperationsTab,
    MglMap,
    geocoderControl,
    MglMarker,
    MglPopup,
    SearchBox,
    IncidentData,
    TrajectoryData
  },
  data() {
    return {
      data: [],
      isEmpty: false,
      mapCenter: [144.94444, -37.80193],
      mapData: [],
      mapZoom: 10,
      isLoading: false,
      zoom: 0,
      activeTab: 0,
      isBottomContentVisible: true,
      tabChanged: false,
      queryData: {},
      clickedMarker: false,
      mapStyle: ''
    }
  },
  async mounted() {
    this.mapStyle = this.getMapStyle
    console.log('Get Me', this.$store.getters.getMe)
  },
  methods: {
    isAllowed,
    ...mapActions({
      resetTableHeaderData: 'safetyModule/resetTableHeaderData',
      setStoreSelectedDataDevice: 'safetyModule/setSelectedDataDevice',
      getConflictEvents: 'safetyModule/getConflictEvents',
      getFilesList:'safetyModule/getFilesList'
    }),
    getHeatMapKey(){
      return `${this.state.tableData.length}_${this.tabChanged}`
    },
    mapboxGl() {
      return mapboxgl
    },
    /**
     * On map load
     * @param map
     */
    onMapLoaded(map) {
      console.log('On map loaded')
        this.map = map.map;

        //this.isLoading = false;
        //this.$store.map = map.map;
        this.map.on('zoom', () => {
          this.zoom = this.map.getZoom();
        });
        this.map.on('click',(e)=>{
          console.log('Clicked on map: ', e.lngLat)
          if(!this.clickedMarker){
            this.setSelectedDataDevice(null)
          }
          this.clickedMarker = false
        })

      this.zoomMapToSelectedSiteOrProject(false)
      setTimeout(()=>{
        this.scrollToContent()
      }, 1000)

    },
    changeStyle(style){
      this.mapStyle = style
    },
    onTabChanged(tab) {
      console.log('Tab changed: ', tab)
      if (tab === 1) {
        this.tabChanged = !this.tabChanged
      }
    },
    onQueryChanged(query) {
      console.log('Changed: ', query)
      this.queryData = {
        query: query
      }
    },
    onProjectSelected(){
      this.zoomMapToSelectedSiteOrProject(true)
      this.activeTab = 0
    },
    getMarkerClass(item) {
      let selectedDataDevice = this.state.selectedDataDevice
      return getMarkerClass(item, selectedDataDevice)
    },
    getRatingClass(property){
      return getRiskRatingClass(property)
    },
    async markerClicked(dataDevice) {
      console.log('OnMarkerClicked', dataDevice)
      if (dataDevice != null) {
        if(this.selectedDataDevice != null && this.selectedDataDevice.id === dataDevice.id){
          await this.setSelectedDataDevice(null)
        }else{
          this.clickedMarker = true
          await this.setSelectedDataDevice(dataDevice)
        }
      }
    },
    async onViewClicked(site) {
      console.log('onViewClicked')
      await this.setSelectedDataDevice(site)
    },
    async setSelectedDataDevice(dataDevice) {
      console.log('SetSelectedDataDevice', dataDevice)
      this.activeTab = 0
      await this.resetTableHeaderData()
      await this.setStoreSelectedDataDevice(dataDevice)
      this.zoomMapToSelectedSiteOrProject(true)
      if (dataDevice != null) {
        this.isLoading = true
        await this.getConflictEvents(dataDevice.id)
        let data = {
          projectId: this.state.selectedProject._id,
          dataDeviceId: this.state.selectedDataDevice.id,
          types: 'stored'
        }
        await this.getFilesList(data)
      }
      this.isLoading = false

      //User deselect site when is in middle on the window, map is visible and bottom view is visible, map not render correctly
      //We must wait to recalculate map height
      if(this.map && dataDevice == null){
        setTimeout(()=>{
          this.map.resize()
        }, 1000)
      }

    },
    zoomMapToSelectedSiteOrProject(animate){
      let dataDevice = this.state.selectedDataDevice
      let allSites = this.filteredSites
      if(dataDevice != null){
        //Zoom to selected site
        if(animate){
          const zoomPoint = {
            center: dataDevice.location.coordinates,
            zoom: 18,
            pitch: 0,
            bearing: 0
          };
          this.map.flyTo(zoomPoint)
        }else{
          this.mapZoom = 18
          this.mapCenter = dataDevice.location.coordinates
        }

      }else if(allSites.length > 0 && this.state.selectedProject !== null){
        if(allSites.length === 1){
          //Zoom to single site
          if(animate){
            const zoomPoint = {
              center: allSites[0].location.coordinates,
              zoom: 16,
              pitch: 0,
              bearing: 0
            };

            this.map.flyTo(zoomPoint)
          }else{
            this.mapZoom = 16
            this.mapCenter = allSites[0].location.coordinates
          }
          console.log('Zoom to one site: ', allSites[0])
        }else{
          //Zoom to bounding box of all sites in project
          console.log('Zoom to sites: ', allSites)
          let coordinates = allSites.map(site => {return site.location.coordinates})
          let line = lineString(coordinates);
          let bb = bbox(line);
          this.map.fitBounds(bb, {padding: 200})
        }
      }
    },
    scrollToContent(){
      console.log('Scroll to content:', this.$refs.content)
      if(this.$refs.content){
        this.$refs.content.scrollIntoView({ behavior: "smooth" })
      }
    }
  },
  computed: {
    ...mapGetters({
      getMapStyle: 'getMapStyle',
      filteredSites: 'safetyModule/getFilteredSites',
      getAllSites:'safetyModule/getAllSites',
      userRole:'getUserRole',
      tableData: 'safetyModule/getTableData'
    }),
    ...mapState({
      state: state => state.safetyModule,
    }),
    /**
     * When marker is clicked change map height
     * @returns {string}
     */
    mapHeight: function () {
      if (this.state.selectedDataDevice == null) {
        return 'full-height-map'
      } else {
        return 'smaller-height-map'
      }
    },
    /**
     * Check if all modals are closed
     * @returns {boolean}
     */
    checkIfAllModalAreClosed() {
      return !this.isVideoModalActive;
    },
    selectedDataDevice() {
      console.log('Selected site', this.state.selectedDataDevice)
      return this.state.selectedDataDevice
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>