<template src="./event-details.html">

</template>

<script>
import {capitalizeFirstLetter, getDate, getRiskRatingClass, getTime} from "@/utils/utils";
import TrajectoryMap from "@/modules/safety-module/components/trajectory-map/trajectory-map.vue";
import {mapActions, mapState} from "vuex";
import IncidentsTable from "@/modules/safety-module/components/incidents-table/incidents-table.vue";

export default {
  name: "event-details",
  components: {IncidentsTable, TrajectoryMap},
  props: {
    eventId: {
      type: String,
      required: true,
      default: '',
    },
    projectId: {
      type: String,
      required: true,
      default: '',
    },
    dataDeviceId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      videoUrl: null,
      riskRating: '',
      dca: '',
      pet: '',
      kEnergy: '',
      ttc: '',
      width: 980,
      height: 564,
      ratio: 1.0,
      isLoading: false,
      files: []
    }
  },
  created() {
    this.$router.currentRoute.meta.topBarTitle = `Event ID: ${this.eventId}`
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEventHandler)
  },
  async mounted() {
    console.log('data device id: ', this.dataDeviceId)
    console.log('Project id: ', this.projectId)
    this.ratio = this.width / this.height
    console.log('Event id: ', this.eventId)
    window.addEventListener("resize", this.resizeEventHandler);
    this.calculateSizes()

    this.isLoading = true
    //Restore state after reload page
    await this.restoreState({projectId: this.projectId, dataDeviceId: this.dataDeviceId})

    let event = this.stateTableData.find(td => td._id === this.eventId)

    console.log('Event: ', event)
    this.isLoading = false
    if (event != null) {
      this.isLoading = true
      this.pet = event.data.value ? event.data.value >= 0.0 ? event.data.value + ' s' : 'N/A' : 'N/A'
      this.dca = event.data.DCA
      this.riskRating = event.severityRating !== null && event.severityRating !== undefined ? capitalizeFirstLetter(event.severityRating) : 'Undefined'
      this.kEnergy = 'xxx'
      this.ttc = event.ttcValue

      let pathLeadUserPoints = []
      let pathFollowUserPoints = []


      if(event.data.leadRoadUser.path){
        if (event.data.leadRoadUser.path.length > 0) {
          event.data.leadRoadUser.path.forEach(path => {
            if (path.points != null && path.points.length > 0) {
              //get first gps point
              let gps = path.points[0].gps
              if (gps.coordinates) {
                pathLeadUserPoints.push(gps.coordinates)
              }
            }
          })
        }
      }/*else{
        let roadUserResponse = await this.$store.dispatch('getRoadUserData', event.data.leadRoadUser.userId)
        if (roadUserResponse != null && roadUserResponse.path != null) {
          console.log('User path:', roadUserResponse.path)
          if (roadUserResponse.path.length > 0) {
            roadUserResponse.path.forEach(path => {
              if (path.points != null && path.points.length > 0) {
                //get first gps point
                let gps = path.points[0].gps
                if (gps.coordinates) {
                  pathLeadUserPoints.push(gps.coordinates)
                }
              }
            })
          }
        }
      }*/


      if(event.data.followRoadUser.path){
        if (event.data.followRoadUser.path.length > 0) {
          event.data.followRoadUser.path.forEach(path => {
            if (path.points != null && path.points.length > 0) {
              //get first gps point
              let gps = path.points[0].gps
              if (gps.coordinates) {
                pathFollowUserPoints.push(gps.coordinates)
              }
            }
          })
        }
      }/*else{
        let followRoadUserResponse = await this.$store.dispatch('getRoadUserData', event.data.followRoadUser.userId)
        if (followRoadUserResponse != null && followRoadUserResponse.path != null) {
          console.log('User path:', followRoadUserResponse.path)
          if (followRoadUserResponse.path.length > 0) {
            followRoadUserResponse.path.forEach(path => {
              if (path.points != null && path.points.length > 0) {
                //get first gps point
                let gps = path.points[0].gps
                if (gps.coordinates) {
                  pathFollowUserPoints.push(gps.coordinates)
                }
              }
            })
          }
        }
      }*/

      let response = await this.startVideoDownload(event.videoSnippetMetadataId)
      this.videoUrl = response.url

      this.files = [
        {
          url: this.videoUrl,
          name: 'name_1.mp4'
        },
        {
          url: this.videoUrl,
          name: 'name_2.mp4'
        },
        {
          url: this.videoUrl,
          name: 'name_3.mp4'
        }
      ]

      if(this.$refs.trajectoryMap){
        setTimeout(()=>{
          this.$refs.trajectoryMap.loadPath(pathLeadUserPoints, pathFollowUserPoints)
        }, 1000)
      }

      this.isLoading = false
      console.log('Video url: ', this.videoUrl)
    }

  },
  methods: {
    ...mapActions({
      restoreState: 'safetyModule/restoreState',
      startVideoDownload: 'safetyModule/startVideoDownload',
    }),
    calculateSizes() {
      if (this.$refs.videoRef) {
        this.width = Math.round(this.$refs.videoRef.clientWidth)
        this.height = Math.round(this.width / this.ratio)
        this.$refs.trajectoryMap.setMapHeight(this.height - 16)
      }
    },
    resizeEventHandler(event) {
      console.log('Resize event handler:', event)
      this.calculateSizes()
    },
    getDate(date) {
      return getDate(date)
    },
    getTime(date) {
      return getTime(date)
    },
    getRiskColor(risk) {
      return getRiskRatingClass(risk)
    },
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      stateTableData: state => state.safetyModule.tableData,
    }),
    tableData() {

      let tableData = []

      if (this.stateTableData != null) {
        let event = this.stateTableData.find(td => td._id === this.eventId)
        if (event != null) {
          if (event.data.leadRoadUser) {
            tableData.push({
              roadUser: 'Lead',
              date: this.getDate(event.data.leadRoadUser.conflictZoneExitTime),
              time: this.getTime(event.data.leadRoadUser.conflictZoneExitTime),
              type: event.data.leadRoadUser ? event.data.leadRoadUser.roadUserType : '',
              speed: event.data.leadRoadUser && event.data.leadRoadUser.roadUserSpeed ? event.data.leadRoadUser.roadUserSpeed + ' km/h' : '-km/h',
              origin: event.data.leadRoadUser ? event.data.leadRoadUser.origin : '',
              destination: event.data.leadRoadUser ? event.data.leadRoadUser.destination : '',
              movement: event.data.leadRoadUser ? event.data.leadRoadUser.movement : ''
            })
          }

          if (event.data.followRoadUser) {
            tableData.push({
              roadUser: 'Follow',
              date: this.getDate(event.data.followRoadUser.conflictZoneEnterTime),
              time: this.getTime(event.data.followRoadUser.conflictZoneEnterTime),
              type: event.data.followRoadUser ? event.data.followRoadUser.roadUserType : '',
              speed: event.data.followRoadUser && event.data.followRoadUser.roadUserSpeed ? event.data.followRoadUser.roadUserSpeed + ' km/h' : '-km/h',
              origin: event.data.followRoadUser ? event.data.followRoadUser.origin : '',
              destination: event.data.followRoadUser ? event.data.followRoadUser.destination : '',
              movement: event.data.followRoadUser ? event.data.followRoadUser.movement : ''
            })
          }
        }
      }

      return tableData
    }
  },
}
</script>

<style scoped>

</style>