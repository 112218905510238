<template>
  <div>
    <div id="map-container-configurator" style="height: 540px">
      <MglMap
          v-if="configurationData"
          ref="mapboxMapHome"
          container="map-container-configurator"
          :accessToken="$env.VUE_APP_ACCESS_TOKEN"
          :center="mapCenter"
          :zoom="mapZoom"
          :mapStyle="mapStyle"
          @click="onMapClicked"
          @load="onMapLoaded">
        <div v-if="configurationData && configurationData.items">
          <div v-for="(item, index) in configurationData.items" :key="drawMode + index">
            <configuration-marker :item="item" :map-rotation="mapRotation" @onMarkerClicked="markerClicked(item)"
                                  :is-selected="item === selectedItem"
                                  @onUpdateCoordinates="onUpdateCoordinates"
                                  @onRotateFinished="onRotationChanged"
                                  :draw-mode="drawMode">
            </configuration-marker>
          </div>
        </div>
        <map-style-menu @onStyleChanged="changeStyle"></map-style-menu>
        <draw-controls @onDrawModeSelected="onDrawModeSelected" @onDrawModeDelete="onDeleteItem" mode="full"></draw-controls>
        <div class="listing-group-top-left" style="top: 64px;">
          <b-button v-if="selectedItem">{{selectedItem.wayLabel}} -> {{selectedItem.wayKitLabel}}</b-button>
        </div>
      </MglMap>
    </div>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import {mapGetters, mapState} from "vuex";
import {MglMap} from "vue-mapbox";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapStyleMenu from "@/components/map-style-menu/map-style-menu.vue";
import DrawControls from "@/modules/safety-module/components/draw-controls/draw-controls.vue";
import ConfigurationMarker from "@/modules/safety-module/components/configurator-map-input/configuration-marker.vue";
import {bbox, lineString} from "@turf/turf";

export default {
  name: "configurator-map-input",
  components: {ConfigurationMarker, DrawControls, MapStyleMenu, MglMap},
  props: {
    configurationData: {
      type: Object,
      immediate: true,
    },
    selectedItem: {
      type: Object
    }
  },
  data() {
    return {
      mapStyle: '',
      mapCenter: [144.94444, -37.80193],
      mapZoom: 10,
      drawMode: null,
      mapRotation: 0,
      allDrawings: null
    }
  },
  created() {
    this.map = null
    this.mapStyle = this.getMapStyle
    this.popup = null

    console.log('DATA: ', this.tableData)
  },
  mounted() {
    this.mapStyle = this.getMapStyle
    this.draw = null;
  },
  methods: {
    getMapData() {
      return {zoom: this.map.getZoom(), center: this.map.getCenter()}
    },
    onDrawModeSelected(mode) {
      this.drawMode = mode
      if (this.drawMode) {
        if (this.drawMode.includes('add')) {
          this.map._canvas.style.cursor = "crosshair";
        } else if (this.drawMode === 'move') {
          this.map._canvas.style.cursor = "move";
        } else if (this.drawMode === 'rotate') {
          this.map._canvas.style.cursor = "default";
        } else {
          this.map._canvas.style.cursor = "grab";
        }
      }

      console.log('Draw mode', this.drawMode)
    },
    onMapClicked(event) {
      console.log('On map clicked', event)
      if (this.drawMode && this.drawMode.includes('add')) {
        this.$emit('onMapInputClicked', {drawMode: this.drawMode, coordinates: event.mapboxEvent.lngLat})
      } else {
        this.$emit('onMapClicked', event.mapboxEvent.lngLat)
      }
    },
    onUpdateCoordinates(event) {
      this.$emit('onCoordinatesUpdated', event)
    },
    onRotationChanged(data) {
      this.$emit('onRotationChanged', data)
    },
    onDeleteItem() {
      this.$emit('onDeleteItem')
    },
    onMapLoaded(map) {
      console.log('On map loaded')
      let obj = this
      this.map = map.map;
      obj.mapRotation = this.map.getBearing()
      this.map.on('zoom', () => {
        this.zoom = this.map.getZoom();
      });

      this.map.on('rotate', () => {
        console.log('Map rotation', this.map.getBearing())
        obj.mapRotation = this.map.getBearing()
      })

      this.draw = new MapboxDraw({
        controls:{
          line_string: true,
          trash: true
        },
        displayControlsDefault: false,
        styles: [
          // ACTIVE (being drawn)
          // line stroke
          {
            'id': 'gl-draw-line-inactive',
            'type': 'line',
            'filter': ['all', ['==', 'active', 'false'],
              ['==', '$type', 'LineString'],
              ['!=', 'mode', 'static']
            ],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#ffffff',
              'line-width': 8
            }
          },
          {
            'id': 'gl-draw-line-active',
            'type': 'line',
            'filter': ['all', ['==', '$type', 'LineString'],
              ['==', 'active', 'true']
            ],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#fc2d07',
              'line-dasharray': [0.2, 2],
              'line-width': 6
            }
          },]
      });
      this.map.addControl(this.draw, 'top-right');

      this.map.on('draw.create', this.updateDrawn);
      this.map.on('draw.delete', this.updateDrawn);
      this.map.on('draw.update', this.updateDrawn);

      let features = [
        {
          "id": "4aff25408eff0be45ec8a5dbb41c7798",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "coordinates": [
              [
                147.51243105720886,
                -35.161179641872025
              ],
              [
                147.5129226955935,
                -35.16110336282378
              ]
            ],
            "type": "LineString"
          }
        }
      ]

      if (this.configurationData && this.configurationData.drawings) {
        this.draw.set(this.configurationData.drawings);
      }
      console.log('Get map conf in map: ', this.configurationData)
      this.zoomMapToSelectedSiteOrProject(true)

      this.map.resize()
    },
    clearDrawings() {
      this.draw.deleteAll()
    },
    updateDrawn(event) {
      console.log('On draw changed: ', event)
      console.log('All drawings', this.draw.getAll())
      this.$emit('onDrawingsUpdated', this.draw.getAll())
    },
    changeStyle(style) {
      this.mapStyle = style
    },
    mapboxGl() {
      return Mapbox
    },
    markerClicked(item) {
      console.log('Marker clicked map input:', item)
      this.$emit('onMarkerClicked', item)
    },
    zoomMapToSelectedSiteOrProject(animate) {

      if (this.selectedDataDevice) {
        //Zoom to selected site
        let zoomPoint = {
          center: this.selectedDataDevice.location.coordinates,
          zoom: 18,
          pitch: 0,
          bearing: 0
        };
        if (this.configurationData && this.configurationData.center && this.configurationData.zoom) {
          zoomPoint = {
            center: this.configurationData.center,
            zoom: this.configurationData.zoom,
            pitch: 0,
            bearing: 0
          };
        }

        this.map.flyTo(zoomPoint)
      } else {
        const startPoint = {
          center: this.mapCenter,
          zoom: 13,
          pitch: 0,
          bearing: 0
        };
        this.map.flyTo(startPoint)
      }
    },
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      tableData: state => state.safetyModule.tableData
    }),
    ...mapGetters({
      getMapStyle: 'getMapStyle'
    }),
  }
}
</script>

<style scoped>
</style>