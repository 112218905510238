<template>
  <div class="pl-5 pr-5 pt-1 pb-1">
    <div class="is-size-5 has-text-weight-semibold">Speed</div>
    <hr class="solid-thin mt-2 mb-1">

    <div class="columns mt-3">
      <div class="column is-3">
        <div class="is-flex is-flex-direction-column" style="max-width: 200px">
          <b-radio v-model="radioDateAverage" @input="onAverageChanged"
                   native-value="average">
            <div class="is-size-6 has-text-weight-semibold">Average Daily Traffic</div>
          </b-radio>
          <div class="is-flex mt-4">
            <b-radio v-model="radioDateAverage" @input="onAverageChanged"
                     native-value="date">

            </b-radio>
            <b-field label="Select a date" label-position="on-border">
              <b-select label="Select a date" icon="calendar-range"
                        :disabled="radioDateAverage !== 'date'"
                        rounded expanded v-model="selectedDate" @input="onDateChanged">
                <option v-for="date in dates" :value="date" :key="date">{{date}}</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="is-flex is-flex-direction-column" style="max-width: 120px">
          <b-radio v-model="radioTime" @input="onRadioTimeChanged"
                   native-value="15mins">
            <div class="is-size-6 has-text-weight-semibold">15 mins.</div>
          </b-radio>
          <b-radio v-model="radioTime" class="mt-5" @input="onRadioTimeChanged"
                   native-value="hourly">
            <div class="is-size-6 has-text-weight-semibold">Hourly</div>
          </b-radio>
        </div>
      </div>
      <div class="column is-6">
        <div class="is-flex is-flex-direction-column ml-auto" style="max-width: 300px">
          <b-field label="Movement" label-position="on-border" >
            <b-select placeholder="Select movement" rounded expanded @input="onMovementChanged" v-model="selectedMovement">
              <option v-for="movement in allMovements" :value="movement" :key="movement">{{movement}}</option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
    <div id="chart">
      <apex-chart type="line" height="300" :options="chartOptions" :series="series"></apex-chart>
    </div>
  </div>
</template>

<script>
import {getFormatted15Minutes, getFormattedHourData} from "@/utils/utils";

export default {
  name: "speed-operation-chart",
  props: {
    speedReport: {
      type: Object
    },
  },
  data() {
    return {
      radioDateAverage: 'average',
      radioTime: '15mins',
      allMovements:[],
      selectedMovement:null,
      selectedDate: null,
      dates:[],
      series: [{
        name: "Volume",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148,10, 41, 35, 51, 49, 62, 69, 91, 148,10, 41, 35, 51, 49, 62]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 3
        },
/*        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },*/
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00','09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00','18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
        }
      }
    }
  },
  mounted() {
    //this.dates = Object.keys(this.speedReport.date)
    this.getAllMovementsAndDates()
  },
  methods:{
    getAllMovementsAndDates(){

      let allMovements = []
      this.dates = Object.keys(this.speedReport.date)
      if(this.speedReport.averageDailyTraffic.fifteenMinutes){
        let movements = Object.values(this.speedReport.averageDailyTraffic.fifteenMinutes)
        movements.forEach(mov=>{
          allMovements.push(...Object.keys(mov))
        })
        console.log('Get all movements 11: ', allMovements)
      }

      if(this.speedReport.averageDailyTraffic.hourly){
        let movements = Object.values(this.speedReport.averageDailyTraffic.hourly)
        movements.forEach(mov=>{
          allMovements.push(...Object.keys(mov))
        })
      }

      if(this.speedReport.date){
        let dates = Object.values(this.speedReport.date)
        dates.forEach(date => {
          let movementsFifteen = Object.values(date.fifteenMinutes)
          movementsFifteen.forEach(mov=>{
            allMovements.push(...Object.keys(mov))
          })
          let movementsHourly = Object.values(date.hourly)
          movementsHourly.forEach(mov=>{
            allMovements.push(...Object.keys(mov))
          })
        })
      }

      let uniqueItems = [...new Set(allMovements)]
      this.allMovements = uniqueItems

      console.log('Get all movements: ', uniqueItems)

    },
    onRadioTimeChanged(value){
      console.log('On radio time changed', value)
      this.radioTime = value
      if(value === '15mins'){
        let formattedResponse = getFormatted15Minutes()
        let series = JSON.parse(JSON.stringify(this.series))
        let chartOptions = JSON.parse(JSON.stringify(this.chartOptions))
        series[0].data = Object.values(formattedResponse)
        chartOptions.xaxis.categories = Object.keys(formattedResponse)

        this.series = series
        this.chartOptions = chartOptions
      }else if(value === 'hourly'){
        let formattedResponse = getFormattedHourData()
        let series = JSON.parse(JSON.stringify(this.series))
        let chartOptions = JSON.parse(JSON.stringify(this.chartOptions))
        series[0].data = Object.values(formattedResponse)
        chartOptions.xaxis.categories = Object.keys(formattedResponse)

        this.series = series
        this.chartOptions = chartOptions
      }

      setTimeout(()=>{
        this.setData()
      }, 200)
    },
    onAverageChanged(value){
      this.radioDateAverage = value
      setTimeout(()=>{
        this.setData()
      }, 200)
    },
    onDateChanged(value){
      this.selectedDate = value
      setTimeout(()=>{
        this.setData()
      }, 200)
    },
    onMovementChanged(value){
      this.selectedMovement = value
      setTimeout(()=>{
        this.setData()
      }, 200)
    },
    setData(){
      console.log('All inputs: ', [this.selectedMovement, this.selectedDate, this.radioDateAverage, this.radioTime])
    }
  }
}
</script>

<style scoped>

</style>