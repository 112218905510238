<template>
  <div class="pl-5 pr-5 pt-1 pb-1">
    <div class="is-size-5 has-text-weight-semibold">Volume</div>
    <hr class="solid-thin mt-2 mb-1">

    <div class="columns mt-3">
      <div class="column is-3">
        <div class="is-flex is-flex-direction-column" style="max-width: 250px;">
          <b-radio v-model="radioDateAverage"
                   native-value="average" @input="onAverageChanged">
            <div class="is-size-6 has-text-weight-semibold ml-2">Average Daily Traffic</div>
          </b-radio>
          <div class="is-flex mt-4">
          <b-radio v-model="radioDateAverage"  @input="onAverageChanged"
                   native-value="date">

          </b-radio>
          <b-field label="Select a date" label-position="on-border">
            <b-select label="Select a date" icon="calendar-range"
                      :disabled="radioDateAverage !== 'date'"
                      rounded expanded v-model="selectedDate" @input="onDateChanged">
              <option v-for="date in dates" :value="date" :key="date">{{date}}</option>
            </b-select>
          </b-field>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="is-flex is-flex-direction-column" style="max-width: 120px">
          <b-radio v-model="radioTime" @input="onRadioTimeChanged"
                   native-value="fifteenMinutes">
            <div class="is-size-6 has-text-weight-semibold">15 mins.</div>
          </b-radio>
          <b-radio v-model="radioTime" class="mt-5" @input="onRadioTimeChanged"
                   native-value="hourly">
            <div class="is-size-6 has-text-weight-semibold">Hourly</div>
          </b-radio>
        </div>
      </div>
      <div class="column is-6">
        <div class="is-flex is-flex-direction-column ml-auto" style="max-width: 300px">
          <b-field label="Class Type" label-position="on-border" >
            <b-select placeholder="Select a class type" rounded expanded v-model="classType" @input="onClassTypeChanged">
              <option value="All">All</option>
              <option value="vehicles">Vehicles</option>
              <option value="pedestrians">Pedestrians</option>
              <option value="cycles">Cycles</option>
            </b-select>
          </b-field>
          <b-field label="Movement" label-position="on-border">
          <b-dropdown
              v-model="selectedMovements"
              expanded
              multiple
              @change="onMovementChanged"
              aria-role="list">
            <template #trigger>
              <b-button
                  expanded
                  rounded
                  icon-right="chevron-down">
                {{ selectedMovements.length > 0 ? 'Selected movements: ' + selectedMovements.length : 'Select movement'}}
              </b-button>
            </template>


            <b-dropdown-item v-for="movement in allMovements" :value="movement" aria-role="listitem" :key="movement">
              <span>{{movement}}</span>
            </b-dropdown-item>
          </b-dropdown>
          </b-field>
        </div>
      </div>
    </div>
    <div id="chart">
      <apex-chart type="line" height="350" :options="chartOptions" :series="series"></apex-chart>
    </div>
  </div>
</template>

<script>
import {
  getFormatted15Minutes,
  getFormattedHourData,
  getMapData1,
  getMiniChartMap,
  getShortDate,
  getSubClassType
} from "@/utils/utils";

export default {
  name: "volume-operations-chart",
  props: {
    volumeReport: {
      type: Object
    },
  },
  data() {
    return {
      radioDateAverage: 'average',
      radioTime: 'fifteenMinutes',
      allMovements:[],
      selectedMovements:[],
      selectedDate: null,
      dates:[],
      classType: 'All',
      series: [{
        name: "Volume",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148,10, 41, 35, 51, 49, 62, 69, 91, 148,10, 41, 35, 51, 49, 62]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 3
        },
/*        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },*/
/*        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },*/
        grid: {
          show: true,      // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: true  //or just here to disable only x axis grids
            }
          },
          yaxis: {
            lines: {
              show: true  //or just here to disable only y axis
            }
          },
        },
        xaxis: {
          tickAmount: 24,
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#a2a2a2',
            height: 6,
            offsetX: 0,
            offsetY: 0
          },

          labels: {
            //trim: true,
            rotate: 0,
          },

          categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00','09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00','18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
        }
      }
    }
  },
  created() {
    let formattedResponse = getFormatted15Minutes()
    this.series[0].data = Object.values(formattedResponse)
    this.chartOptions.xaxis.categories = Object.keys(formattedResponse)

    this.getAllMovementsAndDates()
    this.setData()
  },
  methods:{
    onRadioTimeChanged(value){
      console.log('On radio time changed', value)
      this.radioTime = value

      setTimeout(()=>{
        this.setData()
      }, 100)
    },
    onAverageChanged(value){
      this.radioDateAverage = value
      setTimeout(()=>{
        this.setData()
      }, 200)
    },
    onDateChanged(value){
      this.selectedDate = value
      setTimeout(()=>{
        this.setData()
      }, 200)
    },
    onClassTypeChanged(value){
      this.classType = value
      setTimeout(()=>{
        this.setData()
      }, 200)
    },
    onMovementChanged(value){
      this.selectedMovements = value
      setTimeout(()=>{
        this.setData()
      }, 100)
    },
    setData(){
      let data = {}
      if(this.radioDateAverage === 'average'){
        data = this.volumeReport.averageDailyTraffic[this.radioTime]
      }else if(this.radioDateAverage === 'date'){
        if(this.volumeReport.date[this.selectedDate]){
          data = this.volumeReport.date[this.selectedDate][this.radioTime]
        }else{
          data = {}
        }
      }

      if(Object.keys(data).length > 0){
        let filteredData = {}
        Object.keys(data).forEach(key=>{
          filteredData[key] = {}
          let sum = 0
          Object.keys(data[key]).forEach(itemKey => {
            if(this.selectedMovements.includes(itemKey) || this.selectedMovements.includes('All')){

              Object.keys(data[key][itemKey]).forEach(classKey => {
                if(this.classType === 'All' || this.classType === getSubClassType(classKey)){
                  sum += data[key][itemKey][classKey]
                }
              })
            }
          })
          filteredData[key] = sum.toFixed(0)
        })

        console.log('DATAAAA: ', filteredData)

        let merged
        if(this.radioTime === 'fifteenMinutes'){
          merged = {...getFormatted15Minutes(), ...filteredData};
        }else if(this.radioTime === 'hourly'){
          merged = {...getFormattedHourData(), ...filteredData};
        }

        if(merged){
          let series = JSON.parse(JSON.stringify(this.series))
          let chartOptions = JSON.parse(JSON.stringify(this.chartOptions))
          series[0].data = Object.values(merged)
          chartOptions.xaxis.categories = Object.keys(merged)

          this.series = series
          this.chartOptions = chartOptions
        }
        console.log('Dataaa', merged)
      }
    },
    getAllMovementsAndDates(){

      let allMovements = []
      this.dates = Object.keys(this.volumeReport.date)
      if(this.dates && this.dates.length > 0){
        this.selectedDate = this.dates[0]
      }

      if(this.volumeReport.averageDailyTraffic.fifteenMinutes){
        let movements = Object.values(this.volumeReport.averageDailyTraffic.fifteenMinutes)
        movements.forEach(mov=>{
          allMovements.push(...Object.keys(mov))
        })
        console.log('Get all movements 11: ', allMovements)
      }

      if(this.volumeReport.averageDailyTraffic.hourly){
        let movements = Object.values(this.volumeReport.averageDailyTraffic.hourly)
        movements.forEach(mov=>{
          allMovements.push(...Object.keys(mov))
        })
      }

      if(this.volumeReport.date){
        let dates = Object.values(this.volumeReport.date)
        dates.forEach(date => {
          let movementsFifteen = Object.values(date.fifteenMinutes)
          movementsFifteen.forEach(mov=>{
            allMovements.push(...Object.keys(mov))
          })
          let movementsHourly = Object.values(date.hourly)
          movementsHourly.forEach(mov=>{
            allMovements.push(...Object.keys(mov))
          })
        })
      }

      let uniqueItems = [...new Set(allMovements)]
      this.allMovements = uniqueItems

      this.selectedMovements = this.allMovements

      console.log('Get all movements: ', uniqueItems)

    }
  }
}
</script>

<style lang="scss">
/*.button .icon{
  margin-left: auto !important;
  margin-right: calc(-0.5em - 1px);
  height: 1.5em !important;
  width: 1.5em !important;
  font-size: 24px;
}*/
/*.apexcharts-xaxis-label{
  display: none;
&:nth-child(5n){ display:revert; }
}*/
</style>