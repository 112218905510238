<template>
<div>
  <progress-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :progress="progress"
      :message="message"
  ></progress-loading>
  <b-button @click="onDownloadClicked" icon-left="cloud-download" type="is-link" is-light><slot></slot></b-button>
</div>
</template>

<script>
import JSZip from "jszip";
import ProgressLoading from "@/components/progress-loading/progress-loading.vue";

const FileSaver = require('file-saver')
export default {
  name: "download-file",
  components: {ProgressLoading},
  props:{},
  data(){
    return {
      isLoading: false,
      progress: 0,
      message:''
    }
  },
  methods: {
    async downloadFiles(files, zipName){
      console.log('Files:', files)

      this.isLoading = true
      this.progress = 0

      if(files.length > 1){
        //Zip files
        const zip = new JSZip();

        for (let file of files){
          let dataIn = {
            url: file.url,
            callback: this.requestCallback
          }
          this.message = `Downloading file ${file.name}`
          let data = await this.$store.dispatch('downloadFile', dataIn)
          const blob = new Blob([data], { type: 'video/mp4' })
          zip.file(file.name, blob)
        }

        this.message = `Packaging files`
        zip.generateAsync({type:"blob"}).then(function(content) {
          // see FileSaver.js
          FileSaver.saveAs(content, `${zipName}.zip`);
          this.message = `Done`
        });
      }else{
        let dataIn = {
          url: files[0].url,
          callback: this.requestCallback
        }
        this.message = `Downloading file ${files[0].name}`
        let data = await this.$store.dispatch('downloadFile', dataIn)
        const blob = new Blob([data], { type: 'video/mp4' })
        FileSaver.saveAs(blob, files[0].name);
        this.message = `Done`
      }
      this.isLoading = false
    },
    requestCallback(percentage){
      console.log('Callback percentage:', percentage)
      this.progress = percentage
    },
    onDownloadClicked(){
      this.$emit('onDownloadClicked')
    }
  }
}
</script>

<style scoped>

</style>