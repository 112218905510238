import Vue from "vue";
import {Role} from "@/utils/utils";

export default {
    /**
     * Get projects for specific user
     * @param commit
     * @param userId
     * @returns {Promise<*>}
     */
    getMe: async ({commit}) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/uac-me'
        };
        await Vue.prototype.$http(config).then(function (response) {
            console.log('Get me response: ', response.data)
            if (response.data) {
                commit('setMe', response.data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    },
    getProjects: async ({commit, state, getters}) =>{
        if (state.allProjects.length === 0) {
            let config = {
                method: 'post',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-list-v3',
                data:{status:['Active','Inactive']}
            };
            await Vue.prototype.$http(config).then(function (response) {
                commit('updateAllProjects', response.data)
            }).catch(function (error) {
                console.log(error);
            });
        }
    },
    downloadFile: async ({commit, state, getters}, data) =>{
        let config = {
            method: 'GET',
            url: data.url,
            responseType:'arraybuffer',
            onDownloadProgress: (progressEvent)=>{
                let percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                console.log('On progress event: ', percent)
                data.callback(percent)
            }
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                return response.data;
            })
            .catch(function (error) {
                console.log('Download file error', error)
            });
    },
    clearAllUserData: async ({commit})=>{
        //We must clear the data in all modules
        commit('removeUserData')
        commit('safetyModule/clearAllData')
    },
    setMapStyle: async ({commit}, newStyle)=>{
        commit('setMapStyle', newStyle)
    },
}

