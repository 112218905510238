<template>
  <transition :name="animation">
    <div style="z-index: 10000001"
        class="loading-overlay is-active"
        :class="{ 'is-full-page': displayInFullPage }"
        v-show="isActive">
      <div class="loading-background" style="background: #1a1a1a7a" @click="cancel"/>
      <slot>
        <div style="width: 300px; height: 100px; z-index: 10000002">
          <div class="is-size-6 ml-auto mr-auto mb-2 has-text-centered has-text-weight-medium has-text-white">{{message}}</div>
          <b-progress :value="progress" show-value format="percent" size="is-medium">
          </b-progress>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
import {HTMLElement} from "buefy/src/utils/ssr";
import {removeElement} from "buefy/src/utils/helpers";

export default {
  name: "progress-loading",
  model: {
    prop: 'active',
    event: 'update:active'
  },
  props: {
    active: Boolean,
    programmatic: Boolean,
    container: [Object, Function, HTMLElement],
    isFullPage: {
      type: Boolean,
      default: true
    },
    animation: {
      type: String,
      default: 'fade'
    },
    canCancel: {
      type: Boolean,
      default: false
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    progress:{
      default: 0
    },
    message:{
      default: 'Processing...'
    }
  },
  data() {
    return {
      isActive: this.active || false,
      displayInFullPage: this.isFullPage
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    isFullPage(value) {
      this.displayInFullPage = value
    }
  },
  methods: {
    /**
     * Close the Modal if canCancel.
     */
    cancel() {
      if (!this.canCancel || !this.isActive) return

      this.close()
    },
    /**
     * Emit events, and destroy modal if it's programmatic.
     */
    close() {
      this.onCancel.apply(null, arguments)
      this.$emit('close')
      this.$emit('update:active', false)

      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false
        setTimeout(() => {
          this.$destroy()
          removeElement(this.$el)
        }, 150)
      }
    },
    /**
     * Keypress event that is bound to the document.
     */
    keyPress({ key }) {
      if (key === 'Escape' || key === 'Esc') this.cancel()
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keyup', this.keyPress)
    }
  },
  beforeMount() {
    // Insert the Loading component in body tag
    // only if it's programmatic
    if (this.programmatic) {
      if (!this.container) {
        document.body.appendChild(this.$el)
      } else {
        this.displayInFullPage = false
        this.$emit('update:is-full-page', false)
        this.container.appendChild(this.$el)
      }
    }
  },
  mounted() {
    if (this.programmatic) this.isActive = true
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keyup', this.keyPress)
    }
  }
}
</script>

<style scoped>

</style>