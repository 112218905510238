import { render, staticRenderFns } from "./volume-operations-chart.vue?vue&type=template&id=16d5aad4"
import script from "./volume-operations-chart.vue?vue&type=script&lang=js"
export * from "./volume-operations-chart.vue?vue&type=script&lang=js"
import style0 from "./volume-operations-chart.vue?vue&type=style&index=0&id=16d5aad4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports