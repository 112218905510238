<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content p-1">
            <div class="columns m-1" style="background: #fbfbfb">
              <div class="column p-2 pl-4 mt-auto mb-auto">
                <div class="is-size-4 has-text-weight-bold">Files operation</div>
              </div>
              <div class="is-flex">
                <view-guard :permission="'svoc:store-file'">
                  <upload-file></upload-file>
                </view-guard>
                <b-tooltip class="mt-2 mb-2 ml-4 mr-2" label="Refresh table" type="is-black">
                  <view-guard :permission="'svoc:list-file-meta-data'">
                    <b-button icon-left="refresh" @click="refreshTable()"></b-button>
                  </view-guard>
                </b-tooltip>
              </div>
            </div>
            <div class="content p-4">
              <div style="margin-bottom: 32px">
                <b-table v-if="tableData && tableData.length > 0" ref="table" :data="tableData" hoverable>
                  <b-table-column v-slot="props" field="file" label="FILE NAME">
                    <div>{{ props.row.filename }}</div>
                  </b-table-column>
                  <b-table-column v-slot="props" field="file" label="DATE">
                    <div>{{ props.row.utcCaptureStart }}</div>
                  </b-table-column>
                  <b-table-column v-slot="props" field="file" label="ACTIONS" numeric>
                    <b-tooltip v-if="$store.getters.getUserRole === 'Administrator'" type="is-dark" label="Delete file">
                      <view-guard :permission="'svoc:delete-file-meta-data'">
                        <b-button type="is-danger" icon-left="delete" @click="confirmDelete(props.row)"
                                  outlined></b-button>
                      </view-guard>
                    </b-tooltip>
                    <b-tooltip type="is-dark" label="Download file" class="ml-2">
                      <view-guard :permission="'svoc:file-download'">
                        <b-button type="is-success" icon-left="download" @click="downloadFile(props.row)"></b-button>
                      </view-guard>
                    </b-tooltip>
                  </b-table-column>
                </b-table>
                <div v-else style="height: 128px" class="is-flex is-align-items-center">
                  <div class="is-flex is-flex-direction-column is-align-items-center ml-auto mr-auto">
                    <div>
                      <b-icon icon="file-cancel-outline"></b-icon>
                    </div>
                    <div>There are no saved files for this site</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from "@/modules/safety-module/components/upload-file/upload-file.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "files-operations",
  data() {
    return {
      isLoading: false
    }
  },
  components: {ViewGuard, UploadFile},
  computed: {
    ...mapGetters({
      tableData: 'safetyModule/getFileOperationsTableData'
    }),
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
    }),
  },
  methods: {
    ...mapActions({
      prepareDownloadFile: 'safetyModule/prepareDownloadFile',
      deleteFile: 'safetyModule/deleteFile',
      getFilesList: 'safetyModule/getFilesList',
    }),
    async downloadFile(data) {
      console.log('Download file:', data)
      this.isLoading = true
      let response = await this.prepareDownloadFile(data._id)
      window.location.href = response.url;
      this.isLoading = false
    },
    async deleteFileMethod(data) {
      console.log('Delete file: ', data)
      this.isLoading = true
      await this.deleteFile(data._id)
      await this.getFilesList()
      this.isLoading = false
    },
    confirmDelete(data) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: `Are you sure you want to delete ${data.filename} ?`,
        onConfirm: () => this.deleteFileMethod(data)
      })
    },
    async getFilesLst() {
      let listFilesData = {
        projectId: this.selectedProject._id,
        siteId: this.selectedDataDevice.id,
        types: 'stored'
      }
      await this.getFilesList(listFilesData)
    },
    async refreshTable() {
      this.isLoading = true
      await this.getFilesLst()
      this.isLoading = false
    }
  },
}
</script>

<style scoped>

</style>