<template>
  <div style="height: 100vh" class="is-flex is-flex-direction-column">
    <div class="is-align-items-center is-flex is-flex-direction-column mt-auto mb-auto">
      <div class="column has-text-weight-bold has-text-grey-lighter" style="font-size: 72px">403</div>
      <div class="column is-size-1 has-text-weight-bold" style="line-height: 1">We are sorry...</div>
      <div class="column is-size-6 has-text-weight-light has-text-centered">The page you're trying to access has restricted access.<br>Please refer to your system administrator</div>
      <div class="column mt-6"><b-button type="is-dark" @click="$router.back()">Go back</b-button></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "forbidden-page",
  methods:{}
}
</script>

<style scoped>

</style>