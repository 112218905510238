<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="50%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div v-if="wayMarker" class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">Set way labels</h6>
          <button
              type="button"
              class="delete"
              @click="show(false)"/>
        </header>
        <section class="modal-card-body p-5 pb-6">
          <b-field label="Map label">
            <b-input v-model="wayMarker.wayLabel"></b-input>
          </b-field>
          <b-field label="AI Kit label" v-if="aiKitLabels && aiKitLabels.length > 0">
            <b-select placeholder="Select AI Kit Direction" rounded v-model="wayMarker.wayKitLabel" expanded>
              <option v-for="label in aiKitLabels" :value="label" :key="label">{{label}}</option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary is-fullwidth m-2" @click="onCreateWay" rounded>CREATE INDICATOR</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "site-configurator-modal",
  data(){
    return {
      isLoading: false,
      modalActive: false,
      wayMarker:{
        type: Object
      },
      aiKitLabels:{
        type: Array
      }
    }
  },
  methods:{
    show(show, wayMarker, aiKitLabels) {
      this.modalActive = show
      if(this.modalActive){
        this.wayMarker = wayMarker
        this.aiKitLabels = aiKitLabels
      }
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    onCreateWay(){
      let marker = JSON.parse(JSON.stringify(this.wayMarker))
      this.$emit('onCreateWay', marker)
    },
    clearFields() {
      this.wayMarker = null
      this.aiKitLabels = []
    },
  }
}
</script>

<style scoped>

</style>