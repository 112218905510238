<template>
  <div>
    <section>
      <div class="is-flex">
        <button class="button mr-4" style="border-radius: 4px; width: 128px; height: 64px; border-radius: 8px">
          <b-tooltip type="is-dark" label="Sum">
            <b-icon icon="sigma" class="ml-1 has-text-grey"></b-icon>
            <div class="is-size-5 has-text-weight-bold" style="line-height: 1">{{ sumOfEvents ? sumOfEvents : 0 }}</div>
          </b-tooltip>
        </button>

        <div class="columns m-0 p-0" style="width: 100%">
          <div class="column m-0 p-0 mr-1" v-for="item in classTypes" :key="item.name + selectedItems.length"
               style="height: 64px">
            <button class="my-btn mr-1" :class="{'is-selected' : selectedItems.includes(item.name)}"
                    style="width: 100%; height: 100%; border-radius: 8px"
                    @click="setSelected(item.name)">
              <b-tooltip :label="item.name" type="is-dark">
                <div class="is-flex is-flex-direction-column is-align-items-center">
                  <b-icon :icon="item.icon" size="is-small" class="mt-1" style="font-size: 18px"
                          :class="selectedItems.includes(item.name) ? 'has-text-grey' : 'has-text-grey-light'"></b-icon>
                  <div class="has-text-weight-semibold is-size-6"
                       :class="selectedItems.includes(item.name) ? 'has-text-black' : 'has-text-grey-light'">
                    {{ getEventsNumByClassType(item.name) }}
                  </div>
                </div>
              </b-tooltip>
            </button>
          </div>
          <div class="column m-0 p-0" style="height: 64px">
            <button class="my-btn ml-4 mr-0" style="border-radius: 8px; width: 64px; height: 64px"
                    :class="{'is-selected' : selectedItems.length === classTypes.length}"
                    @click="selectAll">
              <b-tooltip :label="selectedItems.length === classTypes.length ? 'Deselect all' : 'Select all'"
                         type="is-dark">
                <b-icon :icon="selectedItems.length === classTypes.length ? 'select-remove' : 'select-all'"
                        :class="selectedItems.length === classTypes.length ? 'has-text-grey' : 'has-text-grey-light'"></b-icon>
              </b-tooltip>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import {getClassTypes} from "@/utils/utils";

export default {
  name: "class-type-view",
  props: {
    itemsData: {
      type: Object
    },
    sumOfEvents: {
      type: Number
    }
  },
  data() {
    return {
      classTypes: [],
      selectedItems: []
    }
  },
  created() {
    this.classTypes = getClassTypes()
    this.selectedItems = this.classTypes.map(item => item.name)
  },
  mounted() {
    this.$emit('onClassTypeChanged', this.selectedItems)
  },
  methods: {
    getEventsNumByClassType(type) {
      if (this.itemsData && this.itemsData[type]) {
        return this.itemsData[type] ? this.itemsData[type].toFixed(0) : 0
      }

      return '0'
    },
    setSelected(name) {
      let index = this.selectedItems.indexOf(name)
      if (index === -1) {
        this.selectedItems.push(name)
      } else {
        this.selectedItems.splice(index, 1)
      }
      this.$emit('onClassTypeChanged', this.selectedItems)
    },
    selectAll() {
      console.log('On select all')
      if (this.selectedItems.length === this.classTypes.length) {
        this.selectedItems = []
      } else {
        this.selectedItems = []
        let itemNames = this.classTypes.map(item => item.name)
        this.selectedItems.push(...itemNames)
      }

      this.$emit('onClassTypeChanged', this.selectedItems)
    }
  },
}
</script>

<style scoped>
.my-btn.is-selected {
  background-color: #efefef;
  border-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.75);
  border-style: solid;
}

.my-btn {
  background-color: hsl(0deg, 0%, 100%);
  border-color: hsl(0deg, 0%, 86%);
  border-width: 1px;
  color: hsl(0deg, 0%, 21%);
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: normal;
  align-items: center;
  line-height: 1.5;
  -webkit-appearance: none;
  border-style: dashed;
}

</style>