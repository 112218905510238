<template src="./template.html"></template>

<script>
export default {
  name: "user-avatar",
  props: {
    user: {
      required: true
    },
    isSmall:{
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    getInitials() {
      const name = this.user.fullName.split(' ')
      return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>