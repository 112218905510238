<template>
  <div>
    <progress-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        :progress="progress"
        :message="message"
    ></progress-loading>
    <div class="is-flex mt-2">
        <b-field class="file mr-2" :class="{'has-name': !!file}" expanded>
          <b-upload v-model="file" class="file-label">
            <span class="file-cta">
                <b-icon class="file-icon" icon="paperclip"></b-icon>
                <span class="file-label">Choose file</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
          </b-upload>
        </b-field>
        <b-button v-if="file" @click="upload" icon-left="upload" type="is-primary">Upload file</b-button>
        <b-button v-else @click="upload" icon-left="upload" type="is-primary" disabled>Upload file</b-button>
    </div>

  </div>
</template>

<script>
import ProgressLoading from "@/components/progress-loading/progress-loading.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "upload-file",
  components: {ProgressLoading},
  data() {
    return {
      isLoading: false,
      file: null,
      progress: 0,
      message:'',
    }
  },
  methods: {
    ...mapActions({
      prepareFileForUpload: 'safetyModule/prepareFileForUpload',
      getFilesList: 'safetyModule/getFilesList'
    }),
    async upload() {

      const data = {
        filename: this.file.name,
        projectId: this.selectedProject._id,
        siteId: this.selectedDataDevice.id,
        timezone: "Australia/Melbourne",
        localCaptureStart: new Date().toISOString(),
        localCaptureEnd: new Date().toISOString(),
        lat: 0.0,
        lon: 0.0,
      };

      this.isLoading = true
      this.message = 'Preparing file for upload...'
      let response = await this.prepareFileForUpload(data)
      if(response.url){

        const waitSeconds = 6
        let timeLeft = waitSeconds
        let processingTimer = setInterval(()=>{
          if(timeLeft <= 0){
            clearInterval(processingTimer);
          }
          this.progress = ((1.0 - (timeLeft / waitSeconds)) * 100.0) | 0
          timeLeft -= 1;
          console.log('Time left', timeLeft)
        }, 1000);

        setTimeout(async ()=>{
          this.message = 'Uploading file...'
          await this.uploadFile(response.url, this.requestCallback)
        }, (waitSeconds + 1) * 1000)
      }

    },
    async requestCallback(data){
      console.log('Callback data: ', data)
      if(data.type === 'done'){
        let listFilesData = {
          projectId: this.selectedProject._id,
          siteId: this.selectedDataDevice.id,
          types: 'stored'
        }

        this.message = 'Processing file...'

        const waitSeconds = 5
        let timeLeft = waitSeconds
        let processingTimer = setInterval(()=>{
          if(timeLeft <= 0){
            clearInterval(processingTimer);
          }
          this.progress = ((1.0 - (timeLeft / waitSeconds)) * 100.0) | 0
          timeLeft -= 1;
          console.log('Time left', timeLeft)
        }, 1000);

        setTimeout(async ()=>{
          this.message = 'Refreshing file list...'
          await this.getFilesList(listFilesData)
          this.message = 'Done.'
          this.isLoading = false
          this.file = null
          this.$buefy.toast.open({
            message: 'File successfully uploaded',
            type: 'is-success',
            duration: 5000,
          })
          this.progress = 0
        }, (waitSeconds + 1) * 1000)


      }else if(data.type === 'progress'){
        this.progress = data.percent
      }
    },
    async uploadFile(url, callback) {
      let xhr = new XMLHttpRequest()
      xhr.upload.onprogress = function (e) {
        let percent = ((e.loaded / e.total) * 100.0) | 0
        console.log('On progress: ', percent)
        callback({
          type: 'progress',
          percent: percent
        })
        // Render a pretty progress bar
      }
      xhr.onreadystatechange = async function () {
        console.log('On ready state change: ', this)
        if (this.readyState === 4) {
          console.log('File is uploaded')
          callback({
            type: 'done'
          })
        }
      }

      xhr.open('PUT', url, true)
      await xhr.send(this.file)
    }
  },
  computed:{
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
    }),
  }

}
</script>

<style scoped>

</style>

