import {lineString, lineIntersect} from "@turf/turf";
import {getSeverityRatingScore} from "@/utils/utils";

export default {
  /**
   * Update all sites after request
   * @param state
   * @param items
   */
  updateAllSites(state, items) {
    state.allSites = items
  },
  setSelectedDataDevice(state, dataDevice) {
    state.selectedDataDevice = dataDevice
  },
  setTableData(state, data) {
    let newData = data.sort((a, b) => {
      return new Date(b.eventAt) - new Date(a.eventAt)
    })

    let petValues = newData.map(data => data.data && data.data.value ? data.data.value : [])


    console.log('Pet values: ', petValues)

    let maxPet = Math.max(...petValues)
    let minPet = Math.min(...petValues)

    console.log('Pet value max: ', maxPet)
    console.log('Pet value min: ', minPet)

    newData.forEach(data => {
      let pathLeadUserPoints = []
      let pathFollowUserPoints = []

      if(data.data && data.data.value){
        petValues.push(data.data.value)
      }

      if(data.data.followRoadUser && data.data.followRoadUser.path){
        if (data.data.followRoadUser.path.length > 0) {
          data.data.followRoadUser.path.forEach(path => {
            if (path.points != null && path.points.length > 0) {
              //get first gps point
              let gps = path.points[0].gps
              if (gps.coordinates) {
                pathFollowUserPoints.push(gps.coordinates)
              }
            }
          })
        }
      }
      if(data.data.leadRoadUser && data.data.leadRoadUser.path){
        if (data.data.leadRoadUser.path.length > 0) {
          data.data.leadRoadUser.path.forEach(path => {
            if (path.points != null && path.points.length > 0) {
              //get first gps point
              let gps = path.points[0].gps
              if (gps.coordinates) {
                pathLeadUserPoints.push(gps.coordinates)
              }
            }
          })
        }
      }

      let normalizedLeadPoints = pathLeadUserPoints.map(point => {
        return [point[1], point[0]]
      })
      let normalizedFollowPoints = pathFollowUserPoints.map(point => {
        return [point[1], point[0]]
      })

      if(normalizedFollowPoints.length > 0 && normalizedLeadPoints.length > 0){
        let lineLead = lineString(normalizedLeadPoints);
        let lineFollow = lineString(normalizedFollowPoints);
        let intersects = lineIntersect(lineLead, lineFollow);

        if(intersects.features.length > 0){
          intersects.features[0].properties['id'] = data._id
          intersects.features[0].properties['pet'] = data.data.value
          intersects.features[0].properties['dca'] = data.data.DCA
          intersects.features[0].properties['severityRating'] = data.severityRating
          if(data.severityRating){
            //intersects.features[0].properties['heat_intensity'] = 100.0 - ((data.data.value/maxPet) * 100.0)
            intersects.features[0].properties['heat_intensity'] = getSeverityRatingScore(data.severityRating)
          }

          data['conflictPoint'] = intersects.features[0]

        }

        data.data.leadRoadUser['line'] = lineLead
        data.data.followRoadUser['line'] = lineFollow
        data['min_pet'] = minPet
        data['max_pet'] = maxPet
      }

    })

    state.tableData = newData
  },
  setSummary(state, data) {
    state.allSites.forEach(site => {
      let eventsForSite = data.filter(dt => dt.siteId === site._id)
      console.log('Events for site: ', eventsForSite)
    })
  },
  setQueryData(state, queryData) {
    state.searchQueryData = queryData
  },
  setSelectedProject(state, project) {
    state.selectedProject = project
  },
  setFileOperationsTableData(state, newData){
    state.fileOperationsTableData = newData
  },
  setTableHeaderData(state, newData){
    state.tableHeaderData = newData
  },
  resetTableHeaderData(state){
    state.tableHeaderData = {
      activeSorted: {
        field: '',
        order: ''
      },
      activeFilterField:'',
      filters:{risk: [], pet: null, dca: null, date: null, time:null, ttc:null, kineticEnergy:null, userTypes:null}
    }
  },
  clearAllData(state) {
    state.allSites = []
    state.allDevices = []
    state.selectedProjectsDevices = []
    state.selectedDataDevice = null
    state.selectedProject = null
    state.tableData = []
    state.fileOperationsTableData = []
    state.tableHeaderData = {
      activeSorted: {
        field: '',
        order: ''
      },
      activeFilterField:'',
      filters:{risk: [], pet: null, dca: null, date: null, time:null, ttc:null, kineticEnergy:null, userTypes:null}
    }
  }
};
